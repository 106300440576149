import React from 'react';
import { formatDate } from '../formatDate';
import Fuse from 'fuse.js';

// filter the initital list to not search by ID, objects, createdBy, createdTs and modTs
// if possible, can also gives array of keys to be filtered
// this works even better with specific .graphql
export const useSearch = () => {
  const [filteredList, setFiltered] = React.useState([]);
  const handleSearch = (origList, filter: string) => {
    setFiltered(origList);

    if (origList) {
      // filtering ID and object inside object
      // array of object should consist of same element so define key by taking first element only
      let objKey = Object.keys(origList[0]);
      let filteredKeys = objKey?.filter(
        key =>
          !key.includes('ID') &&
          // typeof origList[0][key] !== 'object' &&
          !key.includes('created') &&
          !key.match('modTs'),
      );

      let filteredObj = origList?.filter(i => {
        let j = filteredKeys.reduce((val, entry) => {
          if (entry.toLowerCase().includes('date') && val[entry] != null) {
            val[entry] = formatDate(new Date(i[entry]).toISOString());
          } else {
            val[entry] = i[entry];
          }
          // val[entry] = i[entry];
          return val;
        }, {});

        if (
          JSON.stringify(j)
            ?.toLowerCase()
            .includes(filter.toString().toLowerCase())
        )
          return i;
      });

      setFiltered(filteredObj);
    }
  };

  return {
    filteredList,
    handleSearch,
  };
};

/**
 *
 * @param handleSearch
 * 3 parameters (origList, filter, keys)
 *
 * @param keys
 * keys to be search in the object
 *
 * first layer:
 * - {name: "chia"}
 * - keys = ["name"]
 *
 * nested object:
 * - { person: { name: "chia", size: { height: "180", weight: "120"}}}
 * - keys = ["person.name.size.height", "person.name.size.weight"]
 *
 * @param origList
 * original list to be search
 *
 * @param filter
 * filter string or pattern words being search
 * -- empty will return the original list
 */
export const useFuseSearch = () => {
  const [filteredList, setFiltered] = React.useState([]);
  const handleSearch = (origList, filter: string, keys: string[]) => {
    setFiltered(origList);

    if (origList) {
      console.log('keys?', keys);

      const options = {
        // isCaseSensitive: false,
        // includeScore: false,
        shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        // useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // keys: filteredKeys,
        // keys: L,
        keys: keys,
      };

      const myFuse = new Fuse(origList, options);
      const result = myFuse.search(filter);
      const val = result?.map(x => x.item);

      if (val?.length > 0) {
        setFiltered(val);
      } else {
        setFiltered(origList);
      }
    }
  };

  return {
    filteredList,
    handleSearch,
  };
};

export const useSubFuseSearch = () => {
  const [filteredList, setFiltered] = React.useState([]);
  const [originalList, setOriginalList] = React.useState([]);
  const setOriginalListing = (origList: any, customDataChanges?: any) => {
    let tempOrig = origList;
    if (!!customDataChanges) {
      tempOrig?.map(v => customDataChanges(v));
    }

    setFiltered(tempOrig);
    setOriginalList(tempOrig);
  };

  // need to cater for dates later
  // need to cater for something like assigned Status shit and everything
  // display differently from the data itself

  const handleSearch = (filter: string, keys: string[]) => {
    if (originalList) {
      const options = {
        // isCaseSensitive: false,
        // includeScore: false,
        shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        // useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // keys: filteredKeys,
        // keys: L,
        keys: keys,
      };

      const myFuse = new Fuse(originalList, options);
      const result = myFuse.search(filter);
      const val = result?.map(x => x.item);

      if (val?.length > 0) {
        setFiltered(val);
      } else {
        setFiltered(originalList);
      }
    }
  };

  const reset = () => {
    setFiltered(originalList);
  };

  return {
    filteredList,
    handleSearch,
    setOriginalListing,
    reset,
    originalList
  };
};
