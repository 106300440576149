import React, { lazy } from 'react';
import { SubcontractMain } from './SubcontractMain';

const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  })),
);

const HomeIcon = lazy(() =>
  import('./HomeIcon').then(module => ({
    default: module.HomeIcon,
  })),
);

const homeRoutes = [
  // {
  //   props: { exact: true, path: '/' },
  //   component: <HomeIcon />,
  // },
  {
    props: { exact: true, path: '/' },
    component: <SubcontractMain />,
  },
  // {
  //   props: { exact: true, path: '/home' },
  //   component: <HomeIcon />,
  // },
  {
    props: { exact: true, path: '/alternate' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <SubcontractMain />,
  },
];

export default homeRoutes;
