import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { SwitchDialog } from 'components/SwitchDialog/SwitchDialog';
import { formatDate } from 'helpers/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import {
  useGetSubcontractTenderQuery,
  useGetContractQuery,
  useDeleteSubcontractTenderMutation,
  useGetSubcontractorLazyQuery,
  useLoggedInPlatformUserProfileQuery,
  ContPermission,
} from 'generated/graphql';
import {
  useFuseSearch,
  useSearch,
  useSubFuseSearch,
} from 'helpers/hooks/useSearch';
import BlueDollarIcon from 'assets/icons/blue-dollar.svg';
import OrangeDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/dollar-orange.svg';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';

export const SubconTenderListing = (props: any) => {
  const { contractID, subconTenderID, subcontractor }: any = useParams();
  let history = useHistory();
  //   const { setSnackBarMsg, setOpenSnackBar } = useContext(
  //     SnackBarContext as any,
  //   );

  const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

  const [ConSubconStatus, setConSubconStatus] = useState<String>('');

  const { tdlist } = props;
  const { setToDoCount } = props;

  const { loading, error, data } = useLoggedInPlatformUserProfileQuery();
  // const [
  //   loadSubcontractor,
  //   { data: { getSubcontractor } = { getSubcontractor: [] } },
  // ] = useGetSubcontractorLazyQuery({
  //   fetchPolicy: 'network-only',
  // });

  const {
    loading: subconLoadin,
    // error,
    data: { getSubcontractTender } = { getSubcontractTender: [] },
  } = useGetSubcontractTenderQuery({
    onCompleted: ({ getSubcontractTender }) => {
      setToDoCount(getSubcontractTender.length);
      // setOriginalListing(getToDoListing);
    },
    fetchPolicy: 'network-only',
    // variables: { ContractID: contractID },
    // variables: { ID: user?.associateID },
    variables: { orderByAsc: 'subconTenderStatus' },
  });

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  let userAccDetail;
  userAccDetail = {
    companyName: user?.companyName,
    name: data?.loggedInPlatformUserProfile?.contactDetail?.name,
    ...getSubcontractTender,
    ...data?.loggedInPlatformUserProfile!,
  };

  localStorage.setItem('loggedInUser', JSON.stringify(userAccDetail));

  // Query for Subcontracts
  // const {
  //   loading,
  //   error,
  //   data: { getSubcontractTender } = { getSubcontractTender: [] },
  // } = useGetSubcontractTenderQuery({
  //   fetchPolicy: 'network-only',
  //   variables: { ContractID: contractID },
  //   // onCompleted: ({ getSubcontractTender }) => {
  //   //   setOriginalListing(getSubcontractTender, v => {
  //   //     v['closeDateFormatted'] = formatDate(v.closeDate);
  //   //     v['tenderDateFormatted'] = formatDate(v.tenderDate);
  //   //   });
  //   // },
  // });

  useEffect(() => {
    if (getSubcontractTender && getSubcontractTender?.length > 0) {
      setOriginalListing(getSubcontractTender, v => {
        v['closeDateFormatted'] = formatDate(v.closeDate);
        v['tenderDateFormatted'] = formatDate(v.tenderDate);
      });
    }
  }, [getSubcontractTender]);

  //   useEffect(() => {
  //     if (getSubcontractTender && getSubcontractTender?.length > 0) {
  //       setOriginalListing(getSubcontractTender, v => {
  //         v['closeDateFormatted'] = formatDate(v.closeDate);
  //         v['tenderDateFormatted'] = formatDate(v.tenderDate);
  //       });
  //     }
  //   }, [getSubcontractTender]);

  // Query for contracts
  const { data: { getContract } = { getContract: [] } } = useGetContractQuery({
    fetchPolicy: 'network-only',
    variables: { ID: contractID },
  });

  // var subcontractList = getContract?.filter(maincon => {
  //   return maincon.subcontractDetail?.length > 0;
  // });

  // Search Part

  const { filteredList, handleSearch, setOriginalListing } = useSubFuseSearch();

  //Mutation for DeleteSubconTender
  const [
    deleteSubcontractTender,
    { loading: mutationLoading, error: mutationError },
  ] = useDeleteSubcontractTenderMutation({
    onError: error => {
      console.log('ERROR', error);
    },
    onCompleted: data => {
      // setSnackBarMsg(SystemMsgs.deleteRecord());
      // setOpenSnackBar(true);
      history.go(0);
    },
  });

  const deleteSubconTender = (subconTenderID, conID) => {
    deleteSubcontractTender({
      variables: {
        input: {
          ID: subconTenderID,
          contractID: conID,
        },
      },
    });
  };

  //---------------------------SWITCH DIALOG--------------------------

  const [change, setSwitch] = useState(false);

  const handleClickSwitchDialog = () => {
    setSwitch(true);
  };
  const handleCloseSwitchDialog = () => {
    setSwitch(false);
  };

  const [labelState, setLabelState] = useState<string>();

  //   const [
  //     fetchContract,
  //     {
  //       loading: conQueryLoading,
  //       error: conQueryError,
  //       data: { getContract: contractList } = { getContract: [] },
  //     },
  //   ] = useGetContractTitleLazyQuery();

  useEffect(() => {
    setLabelState(getContract[0]?.title);
  }, [getContract]);

  // const { filteredList, handleSearch } = useSearch();
  const array: any[] = getSubcontractTender;

  // useEffect(() => {
  //   if (array && array?.length > 0) {
  //     handleSearch(array, '');
  //   }
  // }, [array]);
  const { handlePermDisabled } = usePermissionChecker();

  return (
    <>
      {/* <MainHeader
        mainBtn="back"
        onClick={() => history.push({ pathname: `/subcon-tender` })}
        // smTitle={user?.name}
        sideBtn="switch"
        onClickAction={handleClickSwitchDialog}
        smTitle={'Subcon Tender'}
        title={getContract[0]?.title}
        routeSegments={[
          { name: 'Subcon Tender' },
          { name: 'Subcon Tender', current: true },
        ]}
        // rightRouteSegments={[{ name: 'Subcontracts', current: true }]}
      /> */}
      <div className="tab-subheader">
        {/* <SearchHeader
          title="Subcon Tender Listing"
          value={`${getSubcontractTender?.length ?? 0}`}
          search
          input={
            <InputBase
              onChange={e => {
                handleSearch(getSubcontractTender, e?.target?.value);
              }}
              placeholder="Search here.."
              inputProps={{ 'aria-label': 'search' }}
            />
          }
        /> */}

        <SearchHeader
          title="Subcon Tender Listing"
          value={`${getSubcontractTender?.length ?? 0}`}
          search
          // fixed
          onChangeAction={e => {
            handleSearch(e?.target?.value, [
              'title',
              'closeDateFormatted',
              'tenderDateFormatted',
              'budgetAmt',
            ]);
          }}
          onCloseAction={() => handleSearch('', [])}
        />
      </div>

      <SwitchDialog
        open={change}
        onClose={handleCloseSwitchDialog}
        name="Contract"
        modeID={contractID}
        mode={'contract'}
        page={'Subcon Tender'}
      />

      <ContentWrapper float>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <>
              {loading && <Loading />}
              <EmptyList
                title="No Record found"
                subtitle="Add a new record now."
              />
            </>
          ) : (
            filteredList?.map((el, index) => (
              <ListItem
                // style={{
                //   textDecoration:
                //     el.subconTenderStatus === 'CLOSED' ? 'line-through' : null,
                // }}
                selected={el.subconTenderStatus === 'CLOSED'}
              >
                <ListItemText
                  primary={
                    <>
                      <span
                        className="xsTitle click-text flex-space"
                        // onClick={() =>
                        //   history.push(
                        //     el.contractStatus === 'TERMINATED' ||
                        //       el.contractStatus === 'CLOSED'
                        //       ? null
                        //       : `/subcon-tender/${el.ID}`,
                        //   )
                        // }
                      >
                        {el.title}
                      </span>
                      <span className="xxTitle">
                        {/* <span className=" color-orange">{`MYR`} &nbsp;</span> */}
                        <span className="color-orange">
                          <IconText
                            icon={
                              <img
                                src={OrangeDollarIcon}
                                style={{ width: '12px' }}
                              />
                            }
                            font="highlight-text xxTitle"
                            children={amtStr(el.budgetAmt)}
                          ></IconText>
                        </span>
                      </span>
                    </>
                  }
                  secondary={
                    <>
                      <span className="desc flex-space">
                        <span>Open: </span>
                        <span className="highlight-text">
                          {el.subconInvitation.length}{' '}
                        </span>
                        <span>Tenderer(s)</span>
                      </span>

                      <span className="desc">
                        {formatDate(el.startDate)} - {formatDate(el.closeDate)}
                      </span>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  {/* <IconButton
                    edge="end"
                    aria-label="delete"
                    aria-controls="menu-list"
                    aria-haspopup="true"
                    onClick={e =>
                      el.subconTenderStatus === 'CLOSED'
                        ? ''
                        : handleClick(e, el?.ID, index, el)
                    }
                  >
                    <MoreVert />
                  </IconButton> */}
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    aria-controls="menu-list"
                    aria-haspopup="true"
                    onClick={e => handleClick(e, el?.ID, index, el)}
                  >
                    <MoreVert />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          )}

          {/* <div>You are on Subcon Tender Subcontract Listing</div> */}
        </List>
      </ContentWrapper>
      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {menu?.obj?.subconTenderStatus !== 'CLOSED' ? (
          <>
            <MenuItem
              disabled={handlePermDisabled({
                contractID: contractID,
                permEnum: ContPermission.SubconTenderTenderInviteesView,
              })}
              onClick={() => history.push(`/home/${menu.ID}/tender-detail`)}
            >
              <span className="">View Tender</span>
            </MenuItem>

            <MenuItem
              disabled={handlePermDisabled({
                contractID: contractID,
                permEnum: ContPermission.SubconTenderEvaluationSelectionCreate,
              })}
              onClick={() =>
                history.push({
                  pathname: `/home/${menu.ID}/tender-invitees-submission`,
                  state: menu.obj,
                })
              }
            >
              <span className="">Tender Submission</span>
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              disabled={handlePermDisabled({
                contractID: contractID,
                permEnum: ContPermission.SubconTenderView,
              })}
              onClick={() => {
                history.push({
                  pathname: `/home/${menu.ID}/tender-detail`,
                });
              }}
            >
              <span className="">View Tender</span>
            </MenuItem>

            <MenuItem
              onClick={() => {
                history.push({
                  pathname: `/home/${menu.ID}/tender-invitee-submission`,
                  state: menu.obj,
                });
              }}
            >
              <span className="">Tender Submission</span>
            </MenuItem>
          </>
        )}
      </Menu>
      {/* <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => history.push(`/home/${menu.ID}/tender-invitees`)}
        >
          <span className="">Tender Invitees</span>
        </MenuItem>

        <MenuItem
          onClick={() =>
            history.push({
              pathname: `/subcon-tender/${contractID}/${menu.ID}/eval-and-select`,
              state: menu.obj,
            })
          }
        >
          <span className="">Evaluation & Selection</span>
        </MenuItem>

        <MenuItem onClick={() => deleteSubconTender(menu.ID, contractID)}>
          <span className="">Delete</span>
        </MenuItem>
      </Menu> */}
      {/* <FloatButton
        data-qa="addButton"
        onClick={() => history.push(`/subcon-tender/${contractID}/add`)}
      /> */}
    </>
  );
};
