import React, { lazy } from 'react';

const Desk = lazy(() =>
  import('./DeskMain').then(module => ({
    default: module.DeskMain,
  })),
);

// const ToDoListing = lazy(() =>
//   import('./ToDoListing').then(module => ({
//     default: module.ToDoListing,
//   })),
// );

// const NotificationListing = lazy(() =>
//   import('./NotificationListing').then(module => ({
//     default: module.NotificationListing,
//   })),
// );

// const BulletinListing = lazy(() =>
//   import('./BulletinListing').then(module => ({
//     default: module.BulletinListing,
//   })),
// );

// const CalendarListing = lazy(() =>
//   import('./CalendarListing').then(module => ({
//     default: module.CalendarListing,
//   })),
// );

const deskRoutes = [
  {
    props: { exact: true, path: `/desk` },
    component: <Desk />,
  },

  // {
  //   props: { exact: true, path: '/work-desk/to-do-listing' },
  //   component: <ToDoListing />,
  // },

  // {
  //   props: { exact: true, path: '/work-desk/notification-listing' },
  //   component: <NotificationListing />,
  // },

  // {
  //   props: { exact: true, path: '/work-desk/bulletin-listing' },
  //   component: <BulletinListing />,
  // },

  // {
  //   props: { exact: true, path: '/work-desk/calendar-listing' },
  //   component: <CalendarListing />,
  // },
];

export default deskRoutes;
