import { Button } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'

interface IFooterProps {
  options?: any
  chipLabel?: string
  value?: string | number
  single?: Boolean
  isHidden?: boolean
  totalAmt?: boolean
  rightLabel?: boolean
  extraNote?: string
  // bottomName?: string
  iconBotton?: boolean
}

export const Footer = (props: IFooterProps) => {
  const {
    chipLabel,
    options,
    value,
    isHidden,
    totalAmt,
    rightLabel,
    extraNote,
    // bottomName,
    iconBotton = false,
  } = props
  let single: boolean = false

  if (options?.length === 1) {
    single = true
  }

  const asIconBotton = !iconBotton

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  return (
    <div className="footer" id="footer" style={{ bottom: '25px' }}>
      {chipLabel && (
        <div className="footer-label">
          <div
            className="chip-label"
            style={{ marginLeft: isDesktop ? '250px' : null }}
          >
            {chipLabel}
          </div>
          <span className="label-value">{value}</span>
        </div>
      )}
      {totalAmt && (
        <div
          className="footer-label"
          style={{
            background: '#ff9800',
            justifyContent: rightLabel ? 'end' : null,
            color: rightLabel ? '#fff' : '',
          }}
        >
          <div className="xsTitle">
            Total:{' '}
            <span
              style={{ color: rightLabel ? '#fff' : '' }}
              className="color-primary-orange"
            >
              {value}
            </span>
          </div>
        </div>
      )}
      {extraNote && (
        <div
          className="footer-label"
          style={{
            background: '#faf2e8',
          }}
        >
          <div className="desc text-noflow" style={{ textAlign: 'center' }}>
            <span style={{ color: 'red' }}>{extraNote}</span>
          </div>
        </div>
      )}
      {isHidden ? null : (
        <div className="footer-btn">
          {options?.map((el, index) =>
            asIconBotton ? (
              <Button
                key={index}
                type="submit"
                color={el.color}
                style={{ minWidth: options.length > 2 ? '50px' : '126px' }}
                variant="contained"
                className={single ? 'single' : ''}
                onClick={el.onClick}
                disabled={el.disabled}
                {...el.props}
              >
                {el.name}
              </Button>
            ) : (
              <>
                <div
                  className="btn container"
                  style={{ marginLeft: '20px', marginRight: '20px' }}
                >
                  <div
                    className="vertical-center"
                    style={{ textAlign: 'center' }}
                  >
                    <Button
                      key={index}
                      type="submit"
                      color={el.color}
                      style={{
                        minWidth: options.length > 2 ? '22px' : '126px',
                        padding: '0px',
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                      variant="contained"
                      className={single ? 'single' : ''}
                      onClick={el.onClick}
                      disabled={el.disabled}
                      {...el.props}
                    >
                      {el.name}
                    </Button>

                    <br />
                    <span className="xsTitle" style={{ textAlign: 'center' }}>
                      {el.bottomName}
                    </span>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      )}
    </div>
  )
}
