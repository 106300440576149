import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import StandardTabs from '@ifca-root/react-component/src/components/Tabs/StandardTabs';
import AppContext from 'containers/App/Store/AppContext';
import { SubconTenderListing } from 'containers/SubcontractTenderModule/SubconTenderListings';
import { SubcontractTenderListing } from 'containers/SubcontractTenderModule/SubcontractTenderListing';
import {
  useGetSubcontractLazyQuery,
  useGetSubcontractorLazyQuery,
  useGetSubcontractQuery,
  useGetSubcontractTenderLazyQuery,
  useGetSubcontractTenderQuery,
} from 'generated/graphql';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { HomeIcon } from './HomeIcon';

export const SubcontractMain = props => {
  const { globalState, dispatch } = useContext(AppContext as any);
  const [change, setSwitch] = useState(false);
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  let history = useHistory();
  const [value, setValue] = useState(0);
  const [toDoCount, setToDoCount] = useState(0);
  const [notiCount, setNotiCount] = useState(0);

  const [data, setData] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [userID, setUserID] = useState<any>();
  const [notiList, setNotiList] = useState<any>();

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    });
  };

  const {
    data: { getSubcontractTender } = { getSubcontractTender: [] },
  } = useGetSubcontractTenderQuery({
    onCompleted: ({ getSubcontractTender }) => {
      console.log('This is subcontract', getSubcontractTender?.length);
      setToDoCount(getSubcontractTender?.length);
      // setUserID(user?.ID);
    },
    // variables: { ID: user?.associateID },
    fetchPolicy: 'network-only',
  });

  // const [
  //   loadSubconTender,
  //   {
  //     data: { getSubcontractTender } = { getSubcontractTender: [] },
  //     loading: tenderLoading,
  //     error: tenderError,
  //     refetch: refetchTender,
  //   },
  // ] = useGetSubcontractTenderLazyQuery({
  //   onCompleted: ({ getSubcontractTender }) => {
  //     setToDoCount(getSubcontractTender?.length);
  //     setData(data?.getSubcontractTender);
  //     // setUserID(user?.ID);
  //   },
  //   // variables: { ID: user?.associateID },
  //   fetchPolicy: 'network-only',
  // });
  const {
    data: { getSubcontract } = { getSubcontract: [] },
  } = useGetSubcontractQuery({
    variables: {
      subcontractorID: user?.associateID,
    },
    onCompleted: ({ getSubcontract }) => {
      setNotiCount(getSubcontract?.length);
    },
    fetchPolicy: 'network-only',
  });
  console.log('This is subcontract', getSubcontract);

  //Example of function create and query definition
  // let cars = [
  //   {
  //     "color": "purple",
  //     "type": "minivan",
  //     "registration": new Date('2017-01-03'),
  //     "capacity": 7
  //   },
  //   {
  //     "color": "red",
  //     "type": "station wagon",
  //     "registration": new Date('2018-03-03'),
  //     "capacity": 5
  //   },
  //   {
  //     "color": "purple",
  //     "type": "minivan",
  //     "registration": new Date('2017-01-03'),
  //     "capacity": 100
  //   },
  // ]
  // const testing = listing => {
  //   let total = 0
  //    listing.filter(el => el?.color == 'purple').forEach(function(e,index) {
  //     total += e?.capacity
  //    })
  //    return total
  // }
  // const [
  //   loadSubcontractor,
  //   { data: { getSubcontractor } = { getSubcontractor: [] } },
  // ] = useGetSubcontractorLazyQuery({
  //   fetchPolicy: 'network-only',
  // });
  // console.log("testing",testing(cars))
  //console.log("testing",testing(cars))

  const [
    loadSubcontractor,
    { data: { getSubcontractor } = { getSubcontractor: [] } },
  ] = useGetSubcontractorLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.loggedInPlatformUserProfile?.associateID) {
      loadSubcontractor({
        variables: {
          ID: data?.loggedInPlatformUserProfile?.associateID,
        },
      });
      // loadSubcontract({
      //   variables: {
      //     subcontractorID: data?.loggedInPlatformUserProfile?.associateID,
      //   },
      // });
      // loadSubconTender({
      //   variables: {
      //     ID: data?.loggedInPlatformUserProfile?.associateID,
      //   },
      // });
    }
  }, [data?.loggedInPlatformUserProfile]);

  return (
    <>
      <MainHeader
        mainBtn="menu"
        smTitle="Subcontractor App"
        title={user?.companyName}
        routeSegments={[{ name: 'Main Menu', current: true }]}
        onClickAction={() => setSwitch(true)}
      />
      <StandardTabs
        value={value}
        onChange={handleChange}
        responsive
        fullWidth
        tabs={[
          {
            label: `Subcon Tender (${toDoCount})`,
            content: (
              // <SubcontractTenderListing
              //   tdlist={getSubcontractTender}
              //   setToDoCount={setToDoCount}
              // />
              <SubconTenderListing
                tdlist={getSubcontractTender}
                setToDoCount={setToDoCount}
              />
            ),
          },
          {
            label: `Subcontract (${notiCount})`,
            content: (
              <HomeIcon trlist={getSubcontract} setNotiCount={setNotiCount} />
            ),
          },
        ]}
      />
    </>
  );
};
