import { yupResolver } from '@hookform/resolvers';
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Logo from 'assets/images/contractxWhite.png';
import Background from 'assets/images/HR-Background.jpg';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import { encryptMessage } from 'helpers/Crypto/JSEncrypt';
import { IAction } from 'helpers/models';
import { CommonYupValidation } from 'helpers/yup';
import { useResetPasswordMutation } from 'generated/graphql';
import React, { Reducer, useReducer } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import * as yup from 'yup';

interface ResetProps {
  newPassword: string;
  confirmPassword: string;
}

interface resetPasswordProps {
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  message: string;
  dialog: boolean;
}
export const ResetPasswordSchema = yup.object().shape({
  newPassword: CommonYupValidation.password(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
export const ResetPassword = () => {
  let history = useHistory();
  const { token }: any = useParams();
  console.log(token, 'token');

  const [ResetPassword, { loading, error }] = useResetPasswordMutation({
    onError: error => {
      console.log('error', error);
      dispatch({
        type: 'dialog',
        payload: true,
      });
      dispatch({
        type: 'message',
        payload: error.graphQLErrors[0].message,
      });
    },
    onCompleted: data => {
      console.log('data', data);
      history.push('https://contractx-dev.ifca.io/login');
    },
  });
  const reducer: Reducer<resetPasswordProps, IAction> = (state, action) => {
    switch (action.type) {
      case 'reset':
        return initialState;
      default:
        return { ...state, [action.type]: action.payload };
    }
  };
  const initialState: resetPasswordProps = {
    showNewPassword: false,
    showConfirmPassword: false,
    dialog: false,
    message: '',
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const { handleSubmit, register, errors, control, getValues } = useForm<
    ResetProps
  >({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onChange',
    resolver: yupResolver(ResetPasswordSchema),
  });
  const onSubmit = data => {
    console.log(data);
    ResetPassword({
      variables: {
        password: encryptMessage(data.newPassword),
        token: token,
      },
    });
  };
  return (
    <>
      {loading && <Loading />}
      <AuthLayout logo={Logo} image={Background}>
        <span className="page-title">Reset Password</span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            <Controller
              as={TextField}
              name="newPassword"
              label="New Password *"
              autoComplete="off"
              fullWidth
              control={control}
              helperText={errors?.newPassword?.message}
              error={errors?.newPassword ? true : false}
              type={state.showNewPassword ? 'text' : 'password'}
              value={getValues('newPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Password"
                      onClick={() =>
                        dispatch({
                          type: 'showNewPassword',
                          payload: !state.showNewPassword,
                        })
                      }
                    >
                      {state.showNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Controller
              as={TextField}
              fullWidth
              helperText={errors?.confirmPassword?.message}
              error={errors?.confirmPassword ? true : false}
              autoComplete="off"
              name="confirmPassword"
              label="Confirm Password*"
              control={control}
              type={state.showConfirmPassword ? 'text' : 'password'}
              value={getValues('confirmPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Password"
                      onClick={() =>
                        dispatch({
                          type: 'showConfirmPassword',
                          payload: !state.showConfirmPassword,
                        })
                      }
                    >
                      {state.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            className="login-btn"
            type="submit"
          >
            Send
          </Button>
        </form>
        <StandardDialog
          fullWidth={true}
          open={state.dialog}
          onClose={() =>
            dispatch({
              type: 'dialog',
              payload: false,
            })
          }
          sections={{
            header: {
              title: 'Erorr',
            },
            body: () => <div>{state.message}</div>,
            footer: {
              actions: [
                {
                  displayText: 'Ok',
                  props: {
                    onClick: () =>
                      dispatch({
                        type: 'dialog',
                        payload: false,
                      }),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
            },
          }}
        />
      </AuthLayout>
    </>
  );
};
