import { ApolloProvider } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import '@ifca-root/react-component/src/assets/styles/app.scss';
import theme from '@ifca-root/react-component/src/assets/theme';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import { Button, Snackbar } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/';
import { setAccessToken } from 'AccessToken';
import 'assets/styles/contractx.scss';
import Layout from 'components/Layout/Layout';
import { ContractClient, contractNodeRefreshUrl } from 'ContractClient';
import { createBrowserHistory } from 'history';
import React, { Suspense, useEffect, useReducer, useState } from 'react';
import { Router } from 'react-router-dom';
import Routes from './Router/Routes';
import AppContext from './Store/AppContext';
import { GlobalInitialState, RootReducer } from './Store/RootReducer';
import SnackBarContext from './Store/SnackBarContext';

export const history = createBrowserHistory();

const App = () => {
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMsg, setSnackBarMsg] = useState<string>('');
  // const { isUpdateAvailable, updateAssets } = useServiceWorker();
  // const [purchaseState, purchaseDispatch] = useReducer(
  //   PurchaseReducer,
  //   PurchaseInitState,
  // );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(contractNodeRefreshUrl, {
      method: 'POST',
      credentials: 'include',
    }).then(async x => {
      const { accessToken } = await x.json();
      setAccessToken(accessToken);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <AppContext.Provider value={{ globalState, dispatch }}>
      <SnackBarContext.Provider value={{ setOpenSnackBar, setSnackBarMsg }}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={ContractClient}>
            {/* <ApolloProvider client={client}> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router history={history}>
                <Layout>
                  <Suspense fallback={<Loading />}>
                    <Routes />
                    {/* <Snackbar
                      open={isUpdateAvailable}
                      message="A new version is available!"
                      onClick={updateAssets}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      action={
                        <Button
                          color="inherit"
                          size="small"
                          onClick={updateAssets}
                        >
                          Reload
                        </Button>
                      }
                    />*/}
                    <SnackBarMsg
                      open={openSnackBar}
                      setOpen={setOpenSnackBar}
                      message={snackBarMsg}
                    />
                  </Suspense>
                </Layout>
              </Router>
            </MuiPickersUtilsProvider>
          </ApolloProvider>
          {/* </ApolloProvider> */}
        </ThemeProvider>
      </SnackBarContext.Provider>
    </AppContext.Provider>
  );
};
export default App;
