import authenticationRoutes from 'containers/AccountModule/AccountRoutes';
import React from 'react';
import { Redirect } from 'react-router-dom';
import homeRoutes from '../../HomeModule/HomeRoutes';
import deskRoutes from '../../DeskModule/DeskRoutes';
import subcontractRoutes from '../../SubcontractModule/SubcontractRoutes';
import accounttransactionRoutes from '../../ProjectAccountModule/AccountTransactionRoutes';
import subconTenderRoutes from '../../SubcontractTenderModule/SubconTenderRoutes';
import ContractRoutes from '../../ContractModule/ContractRoutes';
import DefectManagementRoutes from 'containers/DefectManagementModule/DefectManagementRoutes';
const redirectRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
];

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
];

//error route always at the bottom//
const routes = [
  ...homeRoutes,
  ...deskRoutes,
  ...accounttransactionRoutes,
  ...subcontractRoutes,
  ...ContractRoutes,
  ...subconTenderRoutes,
  ...DefectManagementRoutes,
  ...authenticationRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
