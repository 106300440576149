import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import Logo from 'assets/images/contractxWhite.png';
import Background from 'assets/images/HR-Background.jpg';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import { useActivateUserMutation } from 'generated/graphql';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

export const ActivatedUser = () => {
  const { token }: any = useParams();
  // const jwtDecodeToken = jwtDecode(token);
  // const handleRedirectPath = jwtDecodeToken => {
  //   let path;
  //   switch (jwtDecodeToken?.softwareCode[0]) {
  //     case SoftwareCode.Contract:
  //       path = 'https://contractx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Hr:
  //       path = 'https://hrx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Property:
  //       path = 'https://propertyx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Hotel:
  //       path = 'https://hotelx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Rent:
  //       path = 'https://rentx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Account:
  //       path = 'https://accountx-dev.ifca.io/login';
  //       break;
  //     default:
  //       path = null;
  //       break;
  //   }
  //   return window.location.assign(path);
  // };

  let navigationLoginLink;
  switch (process.env.REACT_APP_LOCAL_DB) {
    case 'local':
      navigationLoginLink = 'http://localhost:3500/login';
      break;
    case 'azure':
      navigationLoginLink = 'https://subcontractorx-dev.ifca.io/login';
      break;
    case 'staging':
      navigationLoginLink = 'https://subcontractorx.ifca.io/login';
      break;
    default:
      navigationLoginLink = 'https://subcontractor.contractx.asia/login';
      break;
  }

  let history = useHistory();
  const [
    ActivatedUser,
    { loading: mutationUpdateLoading, error: mutationUpdateError, data },
  ] = useActivateUserMutation({
    onError: error => {
      console.log('error', error);
    },
    onCompleted: data => {
      console.log('data', data);
      if (data.activatePlatformUser && token) {
        // history.push(navigationLoginLink);
        history.push(`/user/create-password/${token}`);
      } else {
        throw new Error('Activation User Fail.');
      }
    },
  });

  useEffect(() => {
    if (token) {
      ActivatedUser({
        variables: {
          token: token,
        },
      });
    }
  }, [token]);

  return (
    <>
      {mutationUpdateLoading && <Loading />}
      <AuthLayout logo={Logo} image={Background}>
        {data?.activatePlatformUser ? (
          <div className="form-box">
            <span className="page-title">You're almost there!</span>
            <br />
            <span className="page-title">Redirecting...</span>
          </div>
        ) : (
          <div className="form-box">
            <span className="page-title">Something went wrong!</span>
          </div>
        )}
      </AuthLayout>
    </>
  );
};
