import AppBar, { AppBarProps } from '@material-ui/core/AppBar'
import { Avatar, Box, Breadcrumbs, useMediaQuery } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import { SwapHoriz } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useContext } from 'react'
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { ReactComponent as SwitchIcon } from '../../assets/headerIcons/switch.svg'
import { ReactComponent as BackIcon } from '../../assets/headerIcons/back.svg'
import AppContext from 'containers/App/Store/AppContext'

export interface MainHeaderProps {
  onClick?: any
  onClickAction?: any
  routeSegments: any
  mainBtn: string
  sideBtn?: string
  title?: string
  smTitle?: string
  currency?: any
  rightRouteSegments?: any
  customIcon?: string
  rightCustomIcon?: string
  rightLabel?: any
  position?: AppBarProps['position']
  dropDownOptions?: any
  fixMainHeader?: boolean //new changes 5/6/2021 by syafiq ali
  appBarProps?: any
}
export const MainHeader = (props: MainHeaderProps) => {
  const {
    mainBtn,
    sideBtn,
    onClick,
    routeSegments,
    title,
    smTitle,
    onClickAction,
    currency,
    rightRouteSegments,
    customIcon,
    rightCustomIcon,
    position,
    rightLabel,
    dropDownOptions,
    appBarProps,
    // fixMainHeader, //new changes 5/6/2021 by syafiq ali
  } = props
  const theme = useTheme()
  const { globalState } = useContext(AppContext as any)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const handleButtonType = type => {
    let icon
    switch (type) {
      case 'menu':
        icon = <MenuIcon />
        break
      case 'back':
        icon = <BackIcon className="MuiSvgIcon-root" />
        break
      case 'close':
        icon = <CloseIcon />
        break
      case 'switch':
        icon = <SwitchIcon />
        break
      case 'exit':
        icon = <ExitToAppIcon className="transform-180" />
        break
      case 'customIcon':
        icon = <img src={customIcon} />
        break
      case 'image':
        icon = (
          <Avatar
            variant="square"
            alt="ifca"
            src={customIcon}
            style={{ width: '30px', height: '30px' }}
            imgProps={{
              loading: 'lazy',
            }}
          />
        )
        break

      default:
        icon = null
        break
    }
    return icon
  }

  const handleActionType = type => {
    let icon
    switch (type) {
      case 'switch':
        icon = <SwitchIcon />
        break
      case 'back':
        icon = <BackIcon />
        break
      case 'exit':
        icon = <ExitToAppIcon />
        break
      case 'dropdown':
        icon = <ArrowDropDownOutlinedIcon />
        break
      case 'company':
        icon = (
          <Avatar
            variant="circle"
            alt="ifca"
            src={rightCustomIcon}
            style={{ width: '30px', height: '30px' }}
            imgProps={{
              loading: 'lazy',
            }}
          />
        )

      default:
        icon = null
        break
    }
    return icon
  }

  return (
    //new changes 5/6/2021 by syafiq ali
    <div
      className={`${
        globalState.drawerOpen ? 'main-header-fix' : 'main-header-fix-fw'
      }`}
    >
      {/* new changes 5/6/2021 by syafiq ali */}
      <AppBar
        className={`mobile-header fix-responsive`}
        position={position || 'fixed'}
        {...appBarProps}
      >
        <Toolbar>
          {/* {latestDesign ? (
          <div className="domain-new-container">
            {isDesktop && mainBtn === 'menu' ? null : (
              <IconButton className="first-btn" onClick={onClick}>
                {handleButtonType(mainBtn)}
              </IconButton>
            )}
            {mainBtn === 'customIcon' ? (
              <img
                style={{ marginRight: '0.5rem' }}
                src={customIcon}
                alt="icon"
              />
            ) : null}
            <div
              aria-label="dropdown-menu"
              className="domain-session  "
              onClick={onClickAction}
            >
              <div className="domain">{title}</div>
              <IconButton
                aria-label="dropdown-menu"
                className="second-btn"
                onClick={onClickAction}
              >
                {handleActionType(sideBtn)}
              </IconButton>
            </div>
          </div>
        ) : ( */}
          <div className="domain-container">
            {isDesktop && mainBtn === 'menu' ? null : (
              <IconButton className="first-btn" onClick={onClick}>
                {handleButtonType(mainBtn)}
              </IconButton>
            )}
            {mainBtn === 'customIcon' ? (
              <img
                style={{ marginRight: '0.5rem' }}
                src={customIcon}
                alt="icon"
              />
            ) : null}
            <div className="domain-session" onClick={onClickAction}>
              <div className="authorizer" style={{ lineHeight: '0.7rem' }}>
                {smTitle}
              </div>
              <div className="domain">{title}</div>
            </div>
            <div>
              {currency && <span className="currency">{currency}</span>}
              {rightLabel && <span className="">{rightLabel}</span>}
            </div>
            {sideBtn === 'company' ? (
              <Box className="second-btn" onClick={onClickAction}>
                {' '}
                <Avatar
                  variant="circle"
                  alt="ifca"
                  src={rightCustomIcon}
                  style={{
                    width: '24px',
                    height: '24px',
                    boxShadow: ' 0px 0px 4px 2px #ffffff',
                  }}
                  imgProps={{
                    loading: 'lazy',
                  }}
                />
              </Box>
            ) : (
              <Box
                className="second-btn"
                display={isDesktop && sideBtn === 'back' ? 'none' : null}
              >
                <IconButton onClick={onClickAction}>
                  {handleActionType(sideBtn)}
                </IconButton>
              </Box>
            )}
          </div>
          {/* )} */}
          <div style={{ width: '100%', display: 'flex' }}>
            <Breadcrumbs
              className="breadcrumbs"
              separator="/"
              aria-label="breadcrumb"
            >
              {routeSegments?.map((el, index) => {
                return (
                  <span
                    className="breadcrumb-title"
                    key={index}
                    style={{
                      fontWeight: el.current ? 'bolder' : 'normal',
                      lineHeight: '1rem',
                    }}
                  >
                    {' '}
                    {el.current ? el.name : '...'}
                  </span>
                )
              })}
            </Breadcrumbs>
            {rightRouteSegments && (
              <Breadcrumbs
                className="breadcrumbs-right"
                separator="/"
                aria-label="breadcrumb"
              >
                {rightRouteSegments?.map((el, index) => {
                  return (
                    <span
                      className="breadcrumb-title-right"
                      key={index}
                      style={{ fontWeight: el.current ? 'bolder' : 'normal' }}
                    >
                      {' '}
                      {el.name}
                    </span>
                  )
                })}
              </Breadcrumbs>
            )}
          </div>
          {dropDownOptions}
        </Toolbar>
      </AppBar>
    </div>
  )
}
export default MainHeader
