import React, { useState, useContext, useEffect } from 'react';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import { TextField, MenuItem } from '@material-ui/core';
import * as yup from 'yup';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import { CommonYupValidation } from 'helpers/yup';
import { Controller, useForm } from 'react-hook-form';
import { SystemMsgs } from 'helpers/SystemMsg';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
  CostClass,
  //   useCreateSubcontractTenderMutation,
  useGetCostCategoryQuery,
  useDocumentListingLazyQuery,
  useGetContractQuery,
  useGetSubcontractorQuery,
  useGetContractLazyQuery,
  useGetSiteLazyQuery,
  useGetConTaxSchemeQuery,
  useUpdateSubcontractMutation,
} from 'generated/graphql';
import NumberFormat from 'react-number-format';
import { amtNumStr, amtStr } from 'helpers/numFormatter';

interface subContractForm {
  maincon: string;
  site: string;
  subcontractor: string;
  subcontract_No: string;
  subcontract_date: Date;
  subcontractPackage: string;
  subConTitle: string;
  description: string;
  start_date: Date;
  end_date: Date;
  taxSchemeID: string;
  SubcontractAmount: number;
  creditTerm: number;
  Attachment: null;
}

export const SubcontractForm = (props: any) => {
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  let history = useHistory();
  const location = useLocation();
  const { subcontractID }: any = useParams();
  const editData: any = location?.state;
  const contractID = editData?.mainContractDetail?.ID;
  const { mode } = props;
  // state for the amount
  const [Amount, setAmount] = useState(editData?.subcontractSum);
  const [taxPerc, setTaxPerc] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0.0);
  const [conID, setConID] = useState(editData?.mainContractDetail?.ID);

  const [
    updateSubcontract,
    { loading: mutationTenderLoading, error: mutationTenderError },
  ] = useUpdateSubcontractMutation({
    onError: error => {
      console.log('ERROR', error);
    },
    onCompleted: () => {
      history.goBack();
    },
  });

  const {
    loading: costCategoryLoading,
    error,
    data: { getCostCategory } = { getCostCategory: [] },
  } = useGetCostCategoryQuery({
    fetchPolicy: 'network-only',
    variables: {
      contractID: contractID,
      costClass: CostClass.Subcontract,
    },
  });

  // Querying for Site Address
  const [
    loadSite,
    { data: { getSite } = { getSite: [] } },
  ] = useGetSiteLazyQuery({
    fetchPolicy: 'network-only',
  });

  // Query for contracts
  const { data: { getContract } = { getContract: [] } } = useGetContractQuery({
    fetchPolicy: 'network-only',
  });

  // Query for subcontractor
  const {
    data: { getSubcontractor } = { getSubcontractor: [] },
  } = useGetSubcontractorQuery({
    fetchPolicy: 'network-only',
  });

  const [
    selectedStartDate,
    setSelectedStartDate,
  ] = React.useState<Date | null>();
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>();

  const [contractAmt, setcontractAmt] = useState(0);

  useEffect(() => {
    setcontractAmt(
      getContract.filter(arr => arr.ID == editData?.mainContractDetail?.ID)[0]
        ?.contractSum,
    );
  }, [editData, getContract]);

  //validation
  const validationSchema = yup.object().shape({
    maincon: CommonYupValidation.requireField(SystemMsgs.description()),
    subcontractPackage: CommonYupValidation.requireField(
      SystemMsgs.costCategory(),
    ),
    subConTitle: CommonYupValidation.requireField(SystemMsgs.title()),
    subcontract_date: CommonYupValidation.requireField(
      SystemMsgs.dateSelection(),
    ),
    subcontract_No: CommonYupValidation.requireField(
      SystemMsgs.subcontractNo(),
    ),
    taxSchemeID: CommonYupValidation.requireField(SystemMsgs.taxType()),
    description: CommonYupValidation.requireField(SystemMsgs.description()),
    start_date: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    end_date: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    creditTerm: CommonYupValidation.requireField(SystemMsgs.creditTerm()),
    SubcontractAmount: yup
      .number()
      .required('Require a proper amount')
      .max(contractAmt, 'Subcontract sum must be less than contract sum')
      .min(1, 'The amount should not be zero'),
  });

  useEffect(() => {
    if (editData) {
      loadSite({
        variables: { ID: editData?.site.ID },
      });
      loadDoc();
    }
  }, [editData]);

  var loading = false;
  //When the save button is pressed
  const onSubmit = data => {
    console.log('<======================>', data);
    console.log('<<<<<<', editData);
    updateSubcontract({
      // variables: {
      //   input: {
      //     ID: subcontractID,
      //     contractID: data.maincon,
      //     subcontractorID: data.subcontractor,
      //     siteID: data.site,
      //     costCategoryID: data.subcontractPackage,
      //     title: data.subConTitle,
      //     description: data.description,
      //     subcontractSum: parseFloat(amtNumStr(data.SubcontractAmount)),
      //     subcontractDate: data.subcontract_date,
      //     startDate: data.start_date,
      //     endDate: data.end_date,
      //     creditTerm: parseFloat(data.creditTerm),
      //     subcontractNo: data.subcontract_No,
      //     progressRetentionPerc: editData.progressRetentionPerc,
      //     maxRetentionPerc: editData.maxRetentionPerc,
      //     taxSchemeID: data.taxSchemeID,
      //     file: files,
      //   },
      // },
    });
  };

  // Use for attachment
  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment();

  const [
    loadDoc,
    { loading: DocLoad, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    variables: { refID: editData?.ID },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing);
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL));
    },
  });

  const {
    data: { getConTaxScheme } = { getConTaxScheme: [] },
  } = useGetConTaxSchemeQuery({
    onCompleted: () => {},
  });

  const calculateTotalAmt = () => {
    setTotalAmt(Amount + Amount * (taxPerc / 1000));
  };

  useEffect(() => {
    let latestTaxEffective = getConTaxScheme.filter(
      x => x?.ID === editData?.taxDetail?.ID,
    )[0];
    setTaxPerc(latestTaxEffective?.taxEffective[0]?.taxRate);
  }, [editData, getConTaxScheme]);

  useEffect(() => {
    calculateTotalAmt();
  }, [Amount, taxPerc]);

  const { handleSubmit, register, setValue, errors, control } = useForm<
    subContractForm
  >({
    mode: 'all',
    defaultValues: {
      maincon: editData ? editData?.mainContractDetail?.ID : '',
      site: editData ? editData?.site.ID : '',
      subcontractor: editData ? editData?.subcontractorID : '',
      subcontract_date: editData ? editData?.subcontractDate : '',
      subcontract_No: editData ? editData?.subcontractNo : '',
      subcontractPackage: editData ? editData?.costCategoryID : '',
      subConTitle: editData ? editData?.title : '',
      description: editData ? editData?.description : '',
      start_date: editData ? editData?.startDate : new Date(),
      end_date: editData ? editData?.endDate : new Date(),
      SubcontractAmount: editData ? editData?.subcontractSum : null,
      taxSchemeID: editData ? editData?.taxDetail?.ID : null,
      creditTerm: editData ? editData.creditTerm : null,
      Attachment: null,
    },
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      {loading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.goBack()}
        smTitle={user?.name}
        title={user?.companyName}
        currency={`Myr`}
        routeSegments={[{ name: 'Subcontracts ', current: true }]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />
      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <ContentWrapper footer>
          <CardContents>
            <Controller
              render={({ onChange, onBlur, value, name }) => (
                <TextField
                  select
                  label="Main Contract"
                  required
                  name={name}
                  defaultValue={editData ? editData?.contractID : undefined}
                  onChange={data => {
                    loadSite({
                      variables: { contractID: data?.target?.value },
                    });
                    setConID(data?.target?.value);
                    setcontractAmt(
                      getContract.filter(
                        arr => arr.ID === data?.target?.value,
                      )[0]?.contractSum,
                    );
                    console.log(data?.target?.value);
                    register(`maincon`);
                    setValue(`maincon`, data?.target?.value);
                  }}
                >
                  {getContract?.map((contract, index) => (
                    <MenuItem key={index} value={contract.ID}>
                      {contract?.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              label="Contract"
              name="maincon"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
            />

            <Controller
              as={
                <TextField
                  select
                  label="Site Address"
                  defaultValue={editData?.site.ID}
                >
                  {getSite?.map((el, index) => (
                    <MenuItem value={el.ID} key={index}>
                      {el.name}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="site"
              required
              autoComplete="off"
              defaultValue={editData?.site.ID}
              control={control}
              select
              ref={register}
              helperText={errors?.subcontractPackage?.message}
              error={!!errors?.subcontractPackage ? true : false}
            />

            <Controller
              as={
                <TextField select label="Select Subcontractor">
                  {getSubcontractor?.map((el, index) => (
                    <MenuItem value={el.ID} key={index}>
                      {el.name}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="subcontractor"
              required
              autoComplete="off"
              control={control}
              select
              ref={register}
              helperText={errors?.subcontractPackage?.message}
              error={!!errors?.subcontractPackage ? true : false}
            />

            <Controller
              as={TextField}
              name="subcontract_No"
              label="Subcontract No"
              required
              margin="normal"
              control={control}
              ref={register}
              helperText={errors?.subcontract_No?.message}
              error={errors?.subcontract_No ? true : false}
              className="left"
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="subcontract_date"
                margin="normal"
                id="start_date"
                label="Start Date"
                format="dd MMM yyyy"
                value={new Date()}
                onChange={(date: Date | null) => {}}
                control={control}
                ref={register}
                //allowKeyboardControl
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className="right"
              />
            </MuiPickersUtilsProvider>

            <Controller
              as={TextField}
              name="subConTitle"
              label="Subcontract Title"
              required
              control={control}
              ref={register}
              // onChange={e => {
              //   let selectedCust = subcontractorList?.filter(
              //     x => x?.ID === e?.target?.value,
              //   )[0];
              //   setSelectedCustomer(selectedCust);
              // }}
              helperText={errors?.subConTitle?.message}
              error={errors?.subConTitle ? true : false}
            />
            {/* {subcontractorList?.map((el, index) => (
                <MenuItem key={index} value={el?.ID}>
                {el?.name}
                </MenuItem>
            ))} */}

            <Controller
              as={TextField}
              name="description"
              label="Description"
              required
              control={control}
              ref={register}
              helperText={errors?.description?.message}
              error={errors?.description ? true : false}
              // onChange={e => {
              //   let selectedCust = subcontractorList?.filter(
              //     x => x?.ID === e?.target?.value,
              //   )[0];
              //   setSelectedCustomer(selectedCust);
              // }}
            />
            {/* {subcontractorList?.map((el, index) => (
                <MenuItem key={index} value={el?.ID}>
                {el?.name}
                </MenuItem>
            ))} */}

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="start_date"
                margin="normal"
                id="start_date"
                label="Start Date"
                format="dd MMM yyyy"
                value={new Date()}
                onChange={(date: Date | null) => {}}
                control={control}
                ref={register}
                //allowKeyboardControl
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className="left"
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="end_date"
                margin="normal"
                id="end_date"
                label="End Date"
                format="dd MMM yyyy"
                value={new Date()}
                ref={register}
                onChange={(date: Date | null) => {}}
                control={control}
                //allowKeyboardControl
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className="right"
              />
            </MuiPickersUtilsProvider>

            <Controller
              as={
                <TextField select label="Trade Specification">
                  {getCostCategory?.map((el, index) => (
                    <MenuItem value={el.ID} key={index}>
                      {el.name}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="subcontractPackage"
              required
              autoComplete="off"
              control={control}
              select
              ref={register}
              helperText={errors?.subcontractPackage?.message}
              error={!!errors?.subcontractPackage ? true : false}
            />
            <Controller
              name="SubcontractAmount"
              label="Subcontract Amount"
              control={control}
              ref={register}
              defaultValue={editData?.subcontractSum}
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  thousandSeparator
                  label="Subcontract Amount"
                  allowNegative={false}
                  customInput={TextField}
                  multiline={true}
                  isNumericString
                  decimalScale={2}
                  fixedDecimalScale
                  defaultValue={editData?.subcontractSum}
                  onValueChange={values => {
                    let { floatValue } = values;
                    setAmount(floatValue);
                    onChange(
                      (value = values.floatValue),
                      register(`SubcontractAmount`),
                      setValue(`SubcontractAmount`, floatValue),
                    );
                  }}
                  helperText={
                    errors.SubcontractAmount &&
                    errors?.SubcontractAmount?.message
                  }
                  error={errors.SubcontractAmount && errors.SubcontractAmount}
                />
                // label="Budgeted Amount"
                //value={subConTender.budgetedAmount}
                //onChange={handleChange}
                // ref={register}
                // control={control}
              )}
            />
            <Controller
              render={({ onChange, onBlur, value }) => (
                <TextField
                  defaultValue={editData?.taxDetail?.ID}
                  className="left"
                  margin="dense"
                  helperText={errors?.taxSchemeID?.message}
                  error={errors?.taxSchemeID ? true : false}
                  label="Tax"
                  required
                  // disabled={
                  //   mode === 'edit' && associateCheck?.associateChecking
                  // }
                  select
                  //   SelectProps={{
                  //     renderValue: () =>
                  //       `${latestTaxEffective?.code} | ${latestTaxEffective?.taxEffective[0]?.taxRate}%` ||
                  //       null,
                  //   }}
                  onChange={event => {
                    const obj: any = event.target;
                    let latestTaxEffective = getConTaxScheme.filter(
                      x => x?.ID === obj?.value,
                    )[0];
                    // setTaxDisplay(latestTaxEffective);
                    console.log(obj?.value);
                    setTaxPerc(latestTaxEffective?.taxEffective[0]?.taxRate);
                    register(`taxSchemeID`);
                    setValue(`taxSchemeID`, obj?.value);

                    delete errors['taxSchemeID'];
                  }}
                >
                  {getConTaxScheme?.map((el, index) => (
                    <MenuItem
                      style={{ whiteSpace: 'normal' }}
                      value={el.ID}
                      key={index}
                    >
                      <span className="text-noflow">{el.description}</span>
                    </MenuItem>
                  ))}
                </TextField>
              )}
              select
              name="taxSchemeID"
              autoComplete="off"
              control={control}
              fullWidth
              ref={register}
            />

            <Controller
              as={
                <NumberFormat
                  customInput={TextField}
                  allowNegative={false}
                  fullWidth
                  margin="dense"
                  autoComplete="off"
                  className="right"
                  isNumericString
                  // disabled={
                  //   mode === 'edit' && associateCheck?.associateChecking
                  // }
                  required
                  // value={editData?.creditTerm}
                  // onValueChange={value => {
                  //   const { floatValue } = value;
                  // }}
                />
              }
              thousandSeparator
              name="creditTerm"
              className="right"
              label="Credit Term (Days)"
              control={control}
              ref={register}
              helperText={errors?.creditTerm?.message}
              error={errors?.creditTerm ? true : false}
            />

            <NumberFormat
              thousandSeparator
              allowNegative={false}
              customInput={TextField}
              label="Total Amount"
              id="filled-basic"
              // variant="outlined"
              fullWidth
              margin="normal"
              isNumericString
              disabled
              value={amtStr(totalAmt)}
              //   helperText={errors?.description?.message}
              //   error={errors?.description ? true : false}
            />

            <FileUploadInput
              label="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </CardContents>
          <Footer
            options={[
              {
                name: 'Save',
                color: 'primary',
                onClick: () => {
                  console.log('TEsting submit');
                  handleSubmit(onSubmit);
                },
                props: {
                  type: 'submit',
                },
              },
            ]}
          />
        </ContentWrapper>
      </form>
    </>
  );
};
