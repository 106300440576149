import React, { lazy } from 'react';
import { GLClaimDetails } from './GeneralLedgerModule/GLClaimForm/GLClaimDetails';
import { SubcontractClaimSubmitViewForm } from './SubcontractLedgerAccount/SubcontractLedgerForm/SubcontractClaimSubmitViewForm';

const AccountTransactionListing = lazy(() =>
  import('./AccountTransactionListing').then(module => ({
    default: module.AccountTransactionListing,
  })),
);

const AdvancesListing = lazy(() =>
  import('./Advances/AdvancesListing').then(module => ({
    default: module.AdvancesListing,
  })),
);

const SubcontractAdvanceDetail = lazy(() =>
  import(
    './SubcontractLedgerAccount/SubcontractLedgerDetail/SubcontractAdvanceDetail'
  ).then(module => ({
    default: module.SubcontractAdvanceDetail,
  })),
);

const ProgressClaimsListing = lazy(() =>
  import(
    './SubcontractLedgerAccount/SubcontractLedgerListing/SubcontractClaimListing'
  ).then(module => ({
    default: module.ProgressClaimListing,
  })),
);

// const SubcontractClaimForm = lazy(() =>
//   import('./SubcontractLedgerAccount/SubcontractClaimForm').then(module => ({
//     default: module.SubcontractClaimForm,
//   })),
// );

// const SubcontractClaimForm = lazy(() =>
//   import(
//     './SubcontractLedgerAccount/SubcontractLedgerForm/SubcontractClaimForm'
//   ).then(module => ({
//     default: module.SubcontractClaimForm,
//   })),
// );

const ClaimSubmitView = lazy(() =>
  import(
    '../ProjectAccountModule/SubcontractLedgerAccount/SubcontractLedgerForm/SubcontractClaimSubmitViewForm'
  ).then(module => ({
    default: module.SubcontractClaimSubmitViewForm,
  })),
);

const GeneralSubcontractLedgerListing = lazy(() =>
  import(
    './SubcontractLedgerAccount/SubcontractLedgerListing/GeneralSubcontractLedgerListing'
  ).then(module => ({
    default: module.GeneralSubcontractLedgerListing,
  })),
);

const SubcontractPaymentDetail = lazy(() =>
  import(
    './SubcontractLedgerAccount/SubcontractLedgerDetail/SubcontractPaymentDetail'
  ).then(module => ({
    default: module.SubcontractPaymentDetail,
  })),
);

const SubcontractDnDetail = lazy(() =>
  import(
    './SubcontractLedgerAccount/SubcontractLedgerDetail/SubcontractDnDetail'
  ).then(module => ({
    default: module.SubcontractDnDetail,
  })),
);

const SubcontractDnForm = lazy(() =>
  import(
    './SubcontractLedgerAccount/SubcontractLedgerForm/SubcontractDnForm'
  ).then(module => ({
    default: module.SubcontractDnForm,
  })),
);

const SubcontractCnDetail = lazy(() =>
  import(
    './SubcontractLedgerAccount/SubcontractLedgerDetail/SubcontractCnDetail'
  ).then(module => ({
    default: module.SubcontractCnDetail,
  })),
);

const DntoSubconDetail = lazy(() =>
  import(
    './SubcontractLedgerAccount/SubcontractLedgerDetail/DntoSubconDetail'
  ).then(module => ({
    default: module.DntoSubconDetail,
  })),
);

const CntoSubconDetail = lazy(() =>
  import(
    './SubcontractLedgerAccount/SubcontractLedgerDetail/CntoSubconDetail'
  ).then(module => ({
    default: module.CntoSubconDetail,
  })),
);

const SubcontractCnForm = lazy(() =>
  import(
    './SubcontractLedgerAccount/SubcontractLedgerForm/SubcontractCnForm'
  ).then(module => ({
    default: module.SubcontractCnForm,
  })),
);

const DebMobile = lazy(() =>
  import(
    './LedgerMobileViewModule/MobileStandardTemplate/DebitNotePDF/DebitNotePDFMobile'
  ).then(module => ({
    default: module.DebMobile,
  })),
);

const CredMobile = lazy(() =>
  import(
    './LedgerMobileViewModule/MobileStandardTemplate/CreditNotePDF/CreditNotePDFMobile'
  ).then(module => ({
    default: module.CredMobile,
  })),
);

const accounttransactionRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction`,
    },
    component: <AccountTransactionListing />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/advances`,
    },
    component: (
      <GeneralSubcontractLedgerListing
        accountType="subcontract"
        transactionType="advances"
      />
    ),
  },

  // {
  //   props: {
  //     exact: true,
  //     path: `/customer/:customerID/subcontract/:subcontractID/account-transaction/advances`,
  //   },
  //   component: (
  //     <GeneralSubcontractLedgerListing
  //       accountType="subcontract"
  //       transactionType="advances"
  //     />
  //   ),
  // },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/advances/:ID/details/:transactionID`,
    },
    component: <SubcontractAdvanceDetail />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/progress-claims`,
    },
    component: (
      <ProgressClaimsListing
        accountType="subcontract"
        transactionType="progress-claims"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor/:subcontractID/menu/account/progress-claims/:ID/view-submit/',
    },
    component: <ClaimSubmitView formMode="view-submit" />,
  },

  // {
  //   props: {
  //     exact: true,
  //     path: `/subcontract/submenu/account/progress-claims/view-submit/:subcontractID/:transactionID`,
  //   },
  //   component: (
  //     <SubcontractClaimForm
  //       formMode="view-submit"
  //       accountType="subcontract"
  //       transactionStatus="submit"
  //     />
  //   ),
  // },

  // {
  //   props: {
  //     exact: true,
  //     path: `/customer/:customerID/subcontract/:subcontractID/account-transaction/progress-claims`,
  //   },
  //   component: (
  //     <GeneralSubcontractLedgerListing
  //       accountType="subcontract"
  //       transactionType="claims"
  //     />
  //   ),
  // },

  // {
  //   props: {
  //     exact: true,
  //     path: `/subcontract/:subcontractID/progress-claims/add`,
  //   },
  //   component: <SubcontractClaimForm formMode="add" />,
  // },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/debit-notes`,
    },
    component: (
      <GeneralSubcontractLedgerListing
        accountType="subcontract"
        transactionType="debit-notes"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/advances/details/:ID/:transactionID`,
    },
    component: (
      <SubcontractAdvanceDetail
        accountType="subcontract"
        transactionType="advances"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/debit-notes/add`,
    },
    component: (
      <SubcontractDnForm
        formMode="add"
        accountType="subcontract"
        transactionType="debit-notes"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/credit-notes`,
    },
    component: (
      <GeneralSubcontractLedgerListing
        accountType="subcontract"
        transactionType="credit-notes"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/debit-notes/:ID/:transactionID/details/`,
    },
    component: (
      <SubcontractDnDetail
        accountType="subcontract"
        transactionType="debit-notes"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/credit-notes/details/:ID/:transactionID`,
    },
    component: (
      <SubcontractCnDetail
        accountType="subcontract"
        transactionType="credit-notes"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/credit-notes/add`,
    },
    component: (
      <SubcontractCnForm
        formMode="add"
        accountType="subcontract"
        transactionType="credit-notes"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/debit-notes/mobile-view`,
    },
    component: <DebMobile />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/credit-notes/mobile-view`,
    },
    component: <CredMobile />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/payments`,
    },
    component: (
      <GeneralSubcontractLedgerListing
        accountType="subcontract"
        transactionType="payments"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/debit-notes-from-Maincon`,
    },
    component: (
      <GeneralSubcontractLedgerListing
        accountType="subcontract"
        transactionType="debit-notes-to-subcon"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/debit-notes-to-subcon/details/:ID/:transactionID`,
    },
    component: (
      <DntoSubconDetail
        accountType="subcontract"
        transactionType="debit-notes-to-subcon"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/credit-notes-to-subcon/details/:ID/:transactionID`,
    },
    component: (
      <CntoSubconDetail
        accountType="subcontract"
        transactionType="credit-notes-to-subcon"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/payments/details/`,
    },
    component: (
      <SubcontractPaymentDetail
        accountType="subcontract"
        transactionType="payments"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/account-transaction/credit-notes-from-Maincon`,
    },
    component: (
      <GeneralSubcontractLedgerListing
        accountType="subcontract"
        transactionType="credit-notes-to-subcon"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/subcontract/:subcontractID/menu/account/progress-claims/:transactionID/view-submit',
    },
    component: <ClaimSubmitView formMode="view-submit" />,
  },
];

export default accounttransactionRoutes;
