import BizInsight from '@ifca-root/react-component/src/assets/contractIcons/business_insight.svg';
import ContractMgmt from '@ifca-root/react-component/src/assets/contractIcons/contract_mgmt.svg';
import Subcontractor from '@ifca-root/react-component/src/assets/contractIcons/subcontractor.svg';
import SystemAdmin from '@ifca-root/react-component/src/assets/contractIcons/system_admin.svg';
import Claims from '@ifca-root/react-component/src/assets/hrIcons/claims-app.svg';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import {
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import AppContext from 'containers/App/Store/AppContext';
import { formatDate } from 'helpers/formatDate';
import { useSearch } from 'helpers/hooks/useSearch';
import { amtStr } from 'helpers/numFormatter';
import {
  ContractPermission,
  useGetSubcontractLazyQuery,
  useGetSubcontractorLazyQuery,
  useLoggedInPlatformUserProfileQuery,
} from 'generated/graphql';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import OrangeDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/dollar-orange.svg';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';

export const HomeIcon = props => {
  const { globalState, dispatch } = useContext(AppContext as any);
  let history = useHistory();

  // HANDLE ALL QUERIES
  const { loading, error, data } = useLoggedInPlatformUserProfileQuery();
  const [
    loadSubcontractor,
    { data: { getSubcontractor } = { getSubcontractor: [] } },
  ] = useGetSubcontractorLazyQuery({
    fetchPolicy: 'network-only',
  });

  const { trlist } = props;
  const { setNotiCount } = props;

  const [
    loadSubcontract,
    { data: { getSubcontract } = { getSubcontract: [] } },
  ] = useGetSubcontractLazyQuery({
    onCompleted: ({ getSubcontract }) => {
      setNotiCount(getSubcontract?.length);
    },
    fetchPolicy: 'network-only',
  });

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    });
  };

  useEffect(() => {
    if (data?.loggedInPlatformUserProfile?.associateID) {
      loadSubcontractor({
        variables: {
          ID: data?.loggedInPlatformUserProfile?.associateID,
        },
      });
      loadSubcontract({
        variables: {
          subcontractorID: data?.loggedInPlatformUserProfile?.associateID,
        },
      });
    }
  }, [data?.loggedInPlatformUserProfile]);

  let userAccDetail;
  userAccDetail = {
    companyName: getSubcontractor[0]?.name,
    name: data?.loggedInPlatformUserProfile?.contactDetail?.name,
    ...getSubcontractor,
    ...data?.loggedInPlatformUserProfile!,
  };

  // user info
  localStorage.setItem('loggedInUser', JSON.stringify(userAccDetail));

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  const placeHolderID = '4c2fcca2-f182-11ea-ba5f-1fd2a3746ab3';

  const mainMenuList = [
    {
      path: `/tender/${placeHolderID}`,
      icon: ContractMgmt,
      title: `Tenders`,
      //label1: `Active Contract:`,
      //data1: activeContractCount || 0,
      permission: ContractPermission.HomeSubcontractView,
    },
    {
      path: `/subcontract`,
      icon: Subcontractor,
      title: `Subcontracts`,
      //label1: `Active Contract:`,
      //data1: activeContractCount || 0,
      permission: ContractPermission.HomeSubcontractView,
    },
    {
      path: `/subcontender`,
      icon: Subcontractor,
      title: `Subcontender`,
      //label1: `Active Contract:`,
      //data1: activeContractCount || 0,
      permission: ContractPermission.HomeSubcontractView,
    },
    {
      path: `/work-desk`,
      icon: SystemAdmin,
      title: `Work Desk`,
      //label1: `Active Client:`,
      //data1: activeClientCount || 0,
      permission: ContractPermission.HomeSubcontractorAccountView,
      package_name: 'WorkDesk',
    },
    {
      path: `/subcontractor/submenu/account/${placeHolderID}`,
      icon: Claims,
      title: `Accounts`,
      //label1: `Active Subcontract:`,
      //data1: activeSubcontractCount || 0,
      permission: ContractPermission.HomeSubcontractorAccountView,
    },
    {
      path: `/sysAdmin/general-settings`,
      icon: BizInsight,
      title: `General Setting`,
      //label1: `Active Subcontractor:`,
      //data1: activeSubcontractorCount || 0,
      permission: ContractPermission.HomeSubcontractorAccountView,
    },
  ];

  const { filteredList, handleSearch } = useSearch();
  const array: any[] = getSubcontract;

  useEffect(() => {
    if (array && array?.length > 0) {
      handleSearch(array, '');
    }
  }, [array]);

  console.log('data', getSubcontract);
  //////////////////// All the SubcontractorX Listing ///////////////////////////
  return (
    <>
      {/* <MainHeader
        mainBtn="menu"
        onClick={handleDrawer}
        smTitle={"Subcon App"}
        title={user?.companyName}
        currency="MYR"
        //rightRouteSegments={[{ name: 'Subcontracts', current: true }]}
        routeSegments={[{ name: 'Main Menu', current: true }]}
      /> */}
      <div className="tab-subheader">
        <SearchHeader
          title={`Subcontract Listing`}
          value={`${getSubcontract?.length ?? 0}`}
          input={
            <InputBase
              onChange={e => {
                handleSearch(getSubcontract, e?.target?.value);
              }}
              placeholder="Search here.."
              inputProps={{ 'aria-label': 'search' }}
            />
          }
          search
          // fixed
        />
      </div>
      <ContentWrapper float>
        <List className="core-list">
          {/* {mainMenuList?.map(el => { */}
          {filteredList.map((el, index) => (
            <ListItem>
              {/* <IconItem
                  image={ProjectPurchase}
                  imageType="svg"
                  badgeType="default"
                /> */}
              <ListItemText
                primary={
                  <>
                    <span className="xsTitle click-text flex-space">
                      {el.title}
                    </span>
                    <span className="xxTitle">
                      {/* <span className=" color-orange">{`MYR`} &nbsp;</span> */}
                      <IconText
                        icon={
                          <img
                            src={OrangeDollarIcon}
                            style={{ width: '12px' }}
                          />
                        }
                        font="xxTitle highlight-text"
                        children={amtStr(el?.subcontractSum)}
                      ></IconText>
                      {/* <span className=" highlight-text">
                        {amtStr(el?.subcontractSum)}
                      </span> */}
                    </span>
                  </>
                }
                secondary={
                  <>
                    <span className="desc">
                      <span className="fw-medium">
                        {el?.subcontractorDetail?.name}
                      </span>
                    </span>

                    <span className="desc flex-space"> </span>

                    <span className="desc">
                      {' '}
                      {formatDate(el?.startDate)}
                      <span className="desc">-</span>
                      {formatDate(el?.endDate)}
                    </span>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  //key={index}
                  onClick={() =>
                    history.push({
                      // pathname: `/contract/undefined/subcontract`,
                      pathname: `/subcontract/${el?.ID}/menu`,
                    })
                  }
                >
                  <KeyboardArrowRight />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </ContentWrapper>
    </>
  );
};
