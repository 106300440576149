import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppContext from '../Store/AppContext';
import { PrivateRoute } from './PrivateRoute';
import { Login } from 'containers/AccountModule/Login';
import { ForgotPassword } from 'containers/AccountModule/Password/ForgotPassword';
import { ActivatedUser } from 'containers/AccountModule/ActivatedUser';
import { ResetPassword } from 'containers/AccountModule/Password/ResetPassword';

const Routes = () => {
  const { globalState } = useContext(AppContext as any);
  return (
    <Switch>
      <Route path="/user/activate/:token">
        <ActivatedUser />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/reset-password/:token">
        <ResetPassword />
      </Route>

      {globalState.routes.map((el, index) => {
        return (
          <PrivateRoute key={index} {...el.props}>
            {/* <RouteSuspense> */}
            {el.component}
            {/* </RouteSuspense> */}
          </PrivateRoute>
        );
      })}
    </Switch>
  );
};

export default Routes;
