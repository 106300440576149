import { SubconTenderForm } from 'containers/SubcontractTenderModule/SubcontractTenderForm';
import React, { lazy } from 'react';
import { SubcontractForm } from './SubcontractForm';

const SubcontractListing = lazy(() =>
  import('./SubcontractListing').then(module => ({
    default: module.SubcontractListing,
  })),
);

const SubcontractSubMenu = lazy(() =>
  import('./SubcontractSubMenu').then(module => ({
    default: module.SubcontractSubMenu,
  })),
);

const DefectManagementListing = lazy(() =>
  import('../DefectManagementModule/DefectManagementListing').then(module => ({
    default: module.DefectManagementListing,
  })),
);

const DefectManagementForm = lazy(() =>
  import('../DefectManagementModule/DefectManagementForm').then(module => ({
    default: module.DefectManagementForm,
  })),
);

const SubcontractInsuranceListing = lazy(() =>
  import('./SubcontractGuarantee/SubcontractInsuranceListing').then(module => ({
    default: module.SubcontractInsuranceListing,
  })),
);

const SubcontractExtension = lazy(() =>
  import('./SubcontractExtension/ExtensionListing').then(module => ({
    default: module.ExtensionListing,
  })),
);

const SubcontractAccountTransaction = lazy(() =>
  import('../ProjectAccountModule/AccountTransactionListing').then(module => ({
    default: module.AccountTransactionListing,
  })),
);

const SubcontractExtensionForm = lazy(() =>
  import('./SubcontractExtension/ExtensionForm').then(module => ({
    default: module.ExtensionForm,
  })),
);

const SubcontractDetails = lazy(() =>
  import('./SubcontractDetails').then(module => ({
    default: module.SubcontractDetails,
  })),
);

const SubcontractGuaranteeListing = lazy(() =>
  import('./SubcontractGuarantee/SubcontractGuaranteeListing').then(module => ({
    default: module.SubcontractGuaranteeListing,
  })),
);

const SubcontractGuaranteeInsuranceForm = lazy(() =>
  import('./SubcontractGuarantee/SubcontractGuaranteeInsuranceForm').then(
    module => ({
      default: module.GuaranteeInsuranceForm,
    }),
  ),
);

const SubcontractVOListing = lazy(() =>
  import('./SubcontractVO/SubcontractVOListing').then(module => ({
    default: module.SubcontractVOListing,
  })),
);

const SubcontractVOForm = lazy(() =>
  import('./SubcontractVO/SubcontractVOForm').then(module => ({
    default: module.VOForm,
  })),
);

const SubcontractGuaranteeInsuranceDetails = lazy(() =>
  import('./SubcontractGuarantee/SubcontractGuaranteeInsuranceDetails').then(
    module => ({
      default: module.SubcontractGuaranteeInsuranceDetails,
    }),
  ),
);

const SubcontractContactDetails = lazy(() =>
  import('./SubcontractContact/SubcontractContactListing').then(module => ({
    default: module.ConSubconContacts,
  })),
);

const SubcontractVODetails = lazy(() =>
  import('./SubcontractVO/SubcontractVODetails').then(module => ({
    default: module.SubcontractVODetails,
  })),
);

const SubcontractRetentionListing = lazy(() =>
  import('./SubcontractRetention/SubcontractRetentionListing').then(module => ({
    default: module.SubcontractRetentionListing,
  })),
);

const SubcontractRetentionForm = lazy(() =>
  import('./SubcontractRetention/SubcontractRetentionForm').then(module => ({
    default: module.SubcontractRetentionForm,
  })),
);


const SubcontractDigitalDocument = lazy(() =>
  import('./DigitalDocument/SubconDigitalDocument').then(module => ({
    default: module.SubconDigitalDocument,
  })),
);

const SubcontractDigitalDocDetail = lazy(() =>
  import('./DigitalDocument/DigitalDocumentDetails').then(module => ({
    default: module.DigitalDocumentDetails,
  })),
);

const SubRRMobile = lazy(() =>
  import(
    '../ProjectAccountModule/LedgerMobileViewModule/MobileStandardTemplate/SubRetentionPDF/SubRRPDFMobile'
  ).then(module => ({
    default: module.SubRRMobile,
  })),
);

const SubcontractTenderListing = lazy(() =>
  import('../SubcontractTenderModule/SubcontractTenderListing').then(
    module => ({
      default: module.SubcontractTenderListing,
    }),
  ),
);

const subcontractRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:subcontractID/subcontract`,
    },
    component: <SubcontractListing />,
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:subcontractID/menu`,
    },
    component: <SubcontractSubMenu />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/info`,
    },
    component: <SubcontractDetails />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/info/edit`,
    },
    component: <SubcontractForm />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/info/contact-detail/edit`,
    },
    component: <SubcontractContactDetails />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/guarantee`,
    },
    component: <SubcontractGuaranteeListing type={'guarantee'}/>,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/guarantee/add`,
    },
    component: (
      <SubcontractGuaranteeInsuranceForm mode="add" type={'guarantee'} />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/:subcontractID/menu/account-transaction',
    },
    component: <SubcontractAccountTransaction consubcon="subcontract" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/:subcontractID/menu/extension',
    },
    component: <SubcontractExtension consubcon="subcontract" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/:subcontractID/menu/extension/add',
    },
    component: <SubcontractExtensionForm consubcon="subcontract" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/:subcontractID/menu/digital-document',
    },
    component: <SubcontractDigitalDocument />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/:associateID/menu/digital-document/:refTable',
    },
    component: <SubcontractDigitalDocDetail consubcon={'subcontract'} />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/guarantee/:grnInsId/edit`,
    },
    component: (
      <SubcontractGuaranteeInsuranceForm mode="edit" type={'guarantee'} />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/customer/:customerID/subcontract/:subcontractID/defect-management/add',
    },
    component: <DefectManagementForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/guarantee/:grnInsId/view`,
    },
    component: <SubcontractGuaranteeInsuranceDetails type={'guarantee'} />,
  },
  
  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/insurance`,
    },
    component: <SubcontractInsuranceListing type={'insurance'}/>,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/insurance/add`,
    },
    component: (
      <SubcontractGuaranteeInsuranceForm mode="add" type={'insurance'} />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/insurance/:grnInsId/view`,
    },
    component: <SubcontractGuaranteeInsuranceDetails type={'insurance'} />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/insurance/:grnInsId/edit`,
    },
    component: (
      <SubcontractGuaranteeInsuranceForm mode="edit" type={'insurance'} />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/subcontractVO`,
    },
    component: <SubcontractVOListing />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/subcontractVO/add`,
    },
    component: <SubcontractVOForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/subcontractVO/:voId/edit`,
    },
    component: <SubcontractVOForm mode="draft" />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/subcontractVO/:voId/resubmit`,
    },
    component: <SubcontractVOForm mode="resubmit" />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/subcontractVO/:voId`,
    },
    component: <SubcontractVODetails />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/subcontract-retention`,
    },
    component: <SubcontractRetentionListing />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/subcontract-retention/add`,
    },
    component: <SubcontractRetentionForm />,
  },

  {
    props: {
      exact: true,
      // path: `/customer/:customerID/subcontract/:subcontractID/defect`,
      path: `/subcontract/:subcontractID/menu/defect`,
    },
    component: <DefectManagementListing />,
  },

  {
    props: {
      exact: true,
      path: `/subcontract/:subcontractID/menu/retention/mobile-view`,
    },
    component: <SubRRMobile />,
  },

  {
    props: {
      exact: true,
      // path: `/customer/:customerID/contract/:contractID/Tender`,
      path: '/subcontract/:subcontractID/menu/tender',
    },
    component: <SubcontractTenderListing />,
  },
  {
    props: {
      exact: true,
      // path: `/customer/:customerID/contract/:contractID/Tender`,
      path: '/subcontract/:subcontractID/menu/tender/add',
    },
    component: <SubconTenderForm />,
  },
];

export default subcontractRoutes;
