import React, { lazy } from 'react';

const ContractListing = lazy(() =>
  import('./ContractListing').then(module => ({
    default: module.ContractListing,
  })),
);

const ContractRoutes = [
  {
    props: {
      exact: true,
      path: `/customer/:customerID/contract`,
    },
    component: <ContractListing />,
  },
];
export default ContractRoutes;
