import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import AppContext from 'containers/App/Store/AppContext';
import React, { cloneElement, useContext } from 'react';
import { useRouteMatch } from 'react-router';
import Drawer from './Drawer';
import Header from './Header';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    mainContent: {
      position: 'relative',
      top: 64,
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        top: 64,
        marginLeft: -drawerWidth,
        padding: theme.spacing(3),
      },
    },

    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },

    toolbar: theme.mixins.toolbar,
  }),
);

interface Props {
  children: any;
}

const Layout = (props: Props) => {
  const { children } = props;
  const classes = useStyles();
  const theme = useTheme();
  let settings = useRouteMatch({
    path: [
      '/authentication/404',
      '/login',
      '/forgot-password',
      '/user/activate/:token',
      '/reset-password/:token',
    ],
    strict: true,
    sensitive: true,
  });
  // Desktop Checking
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  // Drawer Toggler
  // const [openDrawer, setOpenDrawer] = useState(true)
  // const handleDrawerToggle = () => {
  //   setOpenDrawer(!openDrawer)
  // }
  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    });
  };
  const { globalState, dispatch } = useContext(AppContext as any);
  return (
    <div className={classes.root}>
      {!settings &&
        (isDesktop ? (
          <Header onDrawerOpen={handleDrawer} />
        ) : // <Header onDrawerOpen={handleDrawer} />
        null)}
      {!settings && (
        <Drawer
          onCloseDrawer={isDesktop ? !handleDrawer : handleDrawer}
          open={isDesktop ? globalState.drawerOpen : !globalState.drawerOpen}
          variant={isDesktop ? 'persistent' : 'temporary'}
        />
      )}
      <main
        className={clsx(classes.mainContent, {
          [classes.contentShift]: globalState.drawerOpen,
        })}
      >
        {cloneElement(children, {})}
      </main>
    </div>
  );
};
export default Layout;
