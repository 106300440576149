import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

interface Props {
  space?: string
  font?: string
  children: any
  icon: any
}

const IconText = props => {
  let { children, font, space, icon, onClick, childrenStyle } = props

  //Assing Space to Container
  const useStyles = makeStyles({
    containerSpace: {
      width: space,
    },
  })
  const classes = useStyles(props)

  //Default Font
  if (font == null) font = 'xsTitle'

  return (
    <>
      <span className={`${classes.containerSpace} icon-text`}>
        {icon}
        <span className={font} onClick={onClick} style={childrenStyle}>
          {children}
        </span>
      </span>
    </>
  )
}

export default IconText
