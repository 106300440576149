import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';
import {
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useSearch } from 'helpers/hooks/useSearch';
import {
  useGetSubcontractTitleLazyQuery,
  useGetSubcontractorTitleLazyQuery,
} from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export const SwitchDialog = props => {
  let history = useHistory();
  const { open, onClose, page, modeID, mode, name, transactionType } = props;

  const [
    loadSubcontractData,
    {
      called: subconCalled,
      loading: subconLoading,
      error: subconError,
      data: { getSubcontract } = { getSubcontract: [] },
      refetch: refetchSubcontract,
    },
  ] = useGetSubcontractTitleLazyQuery({
    fetchPolicy: 'network-only',
    // variables:{
    //   subcontractorID :
    // }
  });

  const [
    loadSubcontractorData,
    {
      called: subcontractorCalled,
      loading: subcontractorLoading,
      error: subcontractorError,
      data: { getSubcontractor } = { getSubcontractor: [] },
      refetch: refetchSubcontractor,
    },
  ] = useGetSubcontractorTitleLazyQuery({
    fetchPolicy: 'network-only',
  });

  if (!subconCalled) loadSubcontractData();
  if (!subcontractorCalled) loadSubcontractorData();

  const [contractsId, setContractId] = useState('');

  const dataArray: any[] = getSubcontract;

  //   const handleViewAll = () => {
  //     setContracts(getContract);
  //   };

  //search part -----------------------------------------
  const { filteredList, handleSearch } = useSearch();

  useEffect(() => {
    if (dataArray && dataArray?.length > 0) {
      handleSearch(dataArray, '');
    }
  }, [dataArray, open]);
  //search part -----------------------------------------

  function handleClick(id, title) {
    setContractId(id);
    let path;
    if (mode === 'contract') {
      switch (page) {
        case 'Contract Submenu':
          path = `/contract/submenu/${id}`;
          break;
        case 'Contract Detail':
          path = `/contract/submenu/${id}/detail`;
          break;
        case 'Contract Budget':
          path = `/contract/submenu/${id}/budget`;
          break;
        case 'Contract Nego-Supply':
          path = `/client-contract/${id}/project-purchase/negotiated-supply`;
          break;
        case 'Contract Guarantee':
          path = `/contract/submenu/${id}/guarantee`;
          break;
        case 'Contract Insurance':
          path = `/contract/submenu/${id}/insurance`;
          break;
        case 'Contract VO':
          path = `/contract/submenu/${id}/vo`;
          break;
        case 'Contract VO Health':
          path = `/contract/submenu/${id}/vo-project-health`;
          break;
        case 'Contract Misc':
          path = `/contract/submenu/${id}/misc-expense`;
          break;
        case 'Contract Retention':
          path = `/contract/submenu/${id}/retention`;
          break;
        case 'Contract Extension':
          path = `/contract/submenu/${id}/extension`;
          break;
        case 'Contract Project Purchase':
          path = `/client-contract/${id}/project-purchase`;
          break;
        case 'Purchase Requisition':
          path = `/client-contract/${id}/project-purchase/purchase-requisition`;
          break;
        case 'Purchase Requisition On Behalf':
          path = `/client-contract/${id}/project-purchase/purchase-requisition/on-behalf`;
          break;
        case 'Purchase Order':
          path = `/client-contract/${id}/project-purchase/purchase-order`;
          break;
        case 'Purchase Order On Behalf':
          path = `/client-contract/${id}/project-purchase/purchase-order/on-behalf`;
          break;
        case 'Delivery Order':
          path = `/client-contract/${id}/project-purchase/delivery-order`;
          break;
        case 'Contract Ledger Submenu':
          path = `/contract/submenu/account/${id}`;
          break;
        case 'Contract Ledger':
          switch (transactionType) {
            case 'advances':
              path = `/contract/submenu/account/advances/${id}`;
              break;
            case 'progress-claims':
              path = `/contract/submenu/account/progress-claims/${id}`;
              break;
            case 'retention-claims':
              path = `/contract/submenu/account/retention-claims/${id}`;
              break;
            case 'debit-notes':
              path = `/contract/submenu/account/debit-notes/${id}`;
              break;
            case 'credit-notes':
              path = `/contract/submenu/account/credit-notes/${id}`;
              break;
            case 'receipts':
              path = `/contract/submenu/account/receipts/${id}`;
              break;
            case 'refunds':
              path = `/contract/submenu/account/refunds/${id}`;
              break;
            case 'client-debit-notes':
              path = `/contract/submenu/account/client-debit-notes/${id}`;
              break;
          }
      }
    } else if (mode === 'subcontract') {
      switch (page) {
        case 'Subcontract Menu':
          path = `/subcontract/${id}/menu`;
          break;
        case 'Subcontract Detail':
          path = `/subcontract/${id}/menu/detail`;
          break;
        case 'Subcontract Guarantee':
          path = `/subcontract/${id}/menu/SubcontractGuarantee`;
          break;
        case 'Subcontract Insurance':
          path = `/subcontract/${id}/menu/insurance`;
          break;
        case 'Subcontract VO':
          path = `/subcontract/${id}/menu/vo`;
          break;
        case 'Subcontract Retention':
          path = `/subcontract/${id}/menu/retention`;
          break;
        case 'Subcontract Extension':
          path = `/subcontract/${id}/menu/extension`;
          break;
        case 'Subcontract Ledger Submenu':
          path = `/subcontract/${id}/menu/account`;
          break;
        case 'Subcontract Ledger':
          switch (transactionType) {
            case 'advances':
              path = `/subcontract/${id}/menu/account/advances`;
              break;
            case 'progress-claims':
              path = `/subcontract/${id}/menu/account/progress-claims`;
              break;
            case 'retention-claims':
              path = `/subcontract/${id}/menu/account/retention-claims`;
              break;
            case 'debit-notes':
              path = `/subcontract/${id}/menu/account/debit-notes`;
              break;
            case 'credit-notes':
              path = `/subcontract/${id}/menu/account/credit-notes`;
              break;
            case 'receipts':
              path = `/subcontract/${id}/menu/account/receipts`;
              break;
            case 'refunds':
              path = `/subcontract/${id}/menu/account/refunds`;
              break;
            case 'client-debit-notes':
              path = `/subcontract/${id}/menu/account/client-debit-notes`;
              break;
          }
      }
    } else if (mode === 'client') {
      switch (page) {
        case 'Client Ledger Submenu':
          path = `/client/submenu/account/${id}`;
          break;
        case 'Client Profile':
          path = `/client/submenu/${id}/profile/associate-profile`;
          break;
        case 'Client Ledger':
          switch (transactionType) {
            case 'advances':
              path = `/client/submenu/account/advances/${id}`;
              break;
            case 'claims':
              path = `/client/submenu/account/claims/${id}`;
              break;
            case 'debit-notes':
              path = `/client/submenu/account/debit-notes/${id}`;
              break;
            case 'credit-notes':
              path = `/client/submenu/account/credit-notes/${id}`;
              break;
            case 'receipts':
              path = `/client/submenu/account/receipts/${id}`;
              break;
            case 'refunds':
              path = `/client/submenu/account/refunds/${id}`;
              break;
            case 'client-debit-notes':
              path = `/client/submenu/account/client-debit-notes/${id}`;
              break;
          }
      }
    } else if (mode === 'subcontractor') {
      switch (page) {
        case 'Subcontractor Ledger Submenu':
          path = `/subcontractor/submenu/account/${id}`;
          break;
        case 'Subcontractor Profile':
          path = `/subcontractor/submenu/${id}/profile/associate-profile`;
          break;
        case 'Subcontractor Ledger':
          switch (transactionType) {
            case 'advances':
              path = `/subcontractor/submenu/account/advances/${id}`;
              break;
            case 'claims':
              path = `/subcontractor/submenu/account/claims/${id}`;
              break;
            case 'debit-notes':
              path = `/subcontractor/submenu/account/debit-notes/${id}`;
              break;
            case 'credit-notes':
              path = `/subcontractor/submenu/account/credit-notes/${id}`;
              break;
            case 'payments':
              path = `/subcontractor/submenu/account/payments/${id}`;
              break;
            case 'refunds':
              path = `/subcontractor/submenu/account/refunds/${id}`;
              break;
            case 'debit-notes-to-subcon':
              path = `/subcontractor/submenu/account/debit-notes-to-subcon/${id}`;
              break;
          }
      }
    } else if (mode === 'supplier') {
      switch (page) {
        case 'Supplier Submenu':
          path = `/supplier/submenu/${id}`;
          break;
        case 'Supplier Ledger Submenu':
          path = `/supplier/submenu/account/${id}`;
          break;
        case 'Supplier Profile':
          path = `/supplier/submenu/${id}/profile/associate-profile`;
          break;
        case 'Supplier Ledger':
          switch (transactionType) {
            case 'deposits':
              path = `/supplier/submenu/account/deposits/${id}`;
              break;
            case 'invoices':
              path = `/supplier/submenu/account/invoices/${id}`;
              break;
            case 'debit-notes':
              path = `/supplier/submenu/account/debit-notes/${id}`;
              break;
            case 'credit-notes':
              path = `/supplier/submenu/account/credit-notes/${id}`;
              break;
            case 'payments':
              path = `/supplier/submenu/account/payments/${id}`;
              break;
          }
      }
    }
    history.push(`${path}`);
  }

  return (
    <>
      <StandardDialog
        fullWidth={true}
        open={open}
        onClose={onClose}
        sections={{
          header: {
            title: `Switch ${name}`,
          },
          body: () => (
            <>
              {/* <TextField
                className="m-b-20"
                id="standard-basic"
                label="Search here..."
                fullWidth
              /> */}
              <InputBase
                className="m-b-20"
                fullWidth
                onChange={e => {
                  handleSearch(dataArray, e?.target?.value);
                }}
                placeholder="Search here.."
                inputProps={{ 'aria-label': 'search' }}
              />

              {filteredList?.map((item, index) => {
                return (
                  <>
                    <List className="core-list">
                      {modeID === item.ID? (
                        <ListItem
                          className="bg-blue-light"
                          key={item.ID}
                          onClick={() =>
                            mode === 'client' ||
                            mode === 'subcontractor' ||
                            mode === 'supplier'
                              ? handleClick(item.ID, item.name)
                              : handleClick(item.ID, item.title)
                          }
                        >
                          {/* <IconImg iconimg={CompanyLogo} /> */}
                          <ListItemText
                            primary={
                              <span className="xsTitle">
                                {mode === 'client' ||
                                mode === 'subcontractor' ||
                                mode === 'supplier'
                                  ? item.name
                                  : item.title}
                              </span>
                            }
                          />

                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete">
                              <Check color="primary" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ) : (
                        <ListItem
                          key={item.ID}
                          onClick={() =>
                            mode === 'client' ||
                            mode === 'subcontractor' ||
                            mode === 'supplier'
                              ? handleClick(item.ID, item.name)
                              : handleClick(item.ID, item.title)
                          }
                        >
                          {/* <IconImg iconimg={CompanyLogo} /> */}
                          <ListItemText
                            primary={
                              <span className="xsTitle">
                                {mode === 'client' ||
                                mode === 'subcontractor' ||
                                mode === 'supplier'
                                  ? item.name
                                  : item.title}
                              </span>
                            }
                          />
                        </ListItem>
                      )}
                    </List>
                  </>
                );
              })}
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: onClose,
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
