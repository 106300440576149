import React, { lazy } from 'react';

// defect //
const DefMgmtContractListing = lazy(() =>
  import('./DefMgmtContractListing').then(module => ({
    default: module.DefMgmtContractListing,
  })),
);

const SubcontractSubMenu = lazy(() =>
  import('../SubcontractModule/SubcontractSubMenu').then(module => ({
    default: module.SubcontractSubMenu,
  })),
);

const DefectManagementListing = lazy(() =>
  import('./DefectManagementListing').then(module => ({
    default: module.DefectManagementListing,
  })),
);

const DefectManagementForm = lazy(() =>
  import('../DefectManagementModule/DefectManagementForm').then(module => ({
    default: module.DefectManagementForm,
  })),
);

const DefectManagementDetail = lazy(() =>
  import('./DefectManagementDetail').then(module => ({
    default: module.DefectManagementDetail,
  })),
);

const ActivityLogListing = lazy(() =>
  import('./ActivityLog/ActivityLogListing').then(module => ({
    default: module.ActivityLogListing,
  })),
);

const ActivityLogForm = lazy(() =>
  import('./ActivityLog/ActivityLogForm').then(module => ({
    default: module.ActivityLogForm,
  })),
);

const defectManagementRoutes = [
  {
    props: { exact: true, path: '/defect-management' },
    component: <DefMgmtContractListing />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/:subcontractID/defect',
    },
    component: <DefectManagementListing />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/:subcontractID/defect-management/add',
    },
    component: <DefectManagementForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/defect-management/:contractID/edit/:defectID',
    },
    component: <DefectManagementForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/defect-management/:contractID/detail/:defectID',
    },
    component: <DefectManagementDetail />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/:subcontractID/menu',
    },
    component: <SubcontractSubMenu />,
  },

  {
    props: {
      exact: true,
      path: '/defect-management/:contractID/activity-log/:defectID',
    },
    component: <ActivityLogListing />,
  },
  {
    props: {
      exact: true,
      path: '/defect-management/:contractID/activity-log/:defectID/add',
    },
    component: <ActivityLogForm mode="add" />,
  },
];

export default defectManagementRoutes;
