import React, { lazy } from 'react';

const TenderInviteesListing = lazy(() =>
  import('./TenderInviteesListing').then(module => ({
    default: module.TenderInviteesListing,
  })),
);

const TenderInviteesSubmission = lazy(() =>
  import('./TenderInviteesSubmission').then(module => ({
    default: module.TenderInviteesSubmission,
  })),
);

const SubconTenderDetail = lazy(() =>
  import('./SubconTenderDetail').then(module => ({
    default: module.SubconTenderDetail,
  })),
);


const subconTenderRoutes = [
  // {
  //   props: {
  //     exact: true,
  //     path: `/tender/:subconTenderID/invitees`,
  //   },
  //   component: <TenderInviteesListing />,
  // },

  {
    props: {
      exact: true,
      path: `/home/:subconTenderID/tender-detail`,
    },
    component: <SubconTenderDetail />,
  },

  {
    props: {
      exact: true,
      path: `/home/:subconTenderID/tender-invitees`,
    },
    component: <TenderInviteesListing />,
  },

  {
    props: {
      exact: true,
      path: `/home/:subconTenderID/tender-invitees-submission`,
    },
    component: <TenderInviteesSubmission />,
  },
];
export default subconTenderRoutes;
