import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import { MenuItem, TextField } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ContentWrapper } from 'components/Layout/ContentWrapper';
import { amtNumStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import {
  useCreateSubcontractTenderMutation,
  useUpdateSubcontractTenderMutation,
  useGetCostCategoryQuery,
  CostClass,
  useGetSiteQuery,
  useGetContractQuery,
  useDocumentListingLazyQuery,
} from 'generated/graphql';
import React, { useContext, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useParams, useLocation } from 'react-router';
import moment from 'moment';
import * as yup from 'yup';
// import SnackBarContext from '../App/Store/SnackBarContext';

interface subConTender {
  site: string;
  refNo: string;
  subcontractPackage: string;
  subConTitle: string;
  description: string;
  // Tender_date: Date;
  // Close_date: Date;
  // Start_Time: Date;
  // End_Time: Date;
  tenderDate: string;
  startDate: string;
  startTime: string;
  closeDate: string;
  endTime: string;
  budgetedAmount: number;
  Attachment: null;
}

export const SubconTenderForm = (props: any) => {
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  let history = useHistory();

  const { contractID }: any = useParams();
  const { subconTenderID }: any = useParams();
  const location = useLocation();
  const editData: any = location?.state;
  const { mode } = props;
  // const { setOpenSnackBar, setSnackBarMsg } = useContext(
  //   SnackBarContext as any,
  // );

  // state for SubConTender
  const [subConTender, setSubConTender] = useState({
    refNo: '',
    Subcon_package: '',
    subConTitle: '',
    description: '',
    budgetedAmount: null,
    // Tender_start_date: '',
    // Tender_close_date: '',
    // Tender_start_time: '',
    // Tender_end_time: '',
    tenderDate: '',
    startDate: '',
    startTime: '',
    closeDate: '',
    endTime: '',
  });

  const [
    createSubcontractTender,
    { loading: mutationLoading, error: mutationError },
  ] = useCreateSubcontractTenderMutation({
    onError: error => {
      console.log('ERROR', error);
      // FOR DUPLICATE DIALOG AFTER MUTATION
      setErrMsg(error.message.substr(15, error.message.length - 1));
      setErrorDia(true);
    },
    onCompleted: data => {
      // setSnackBarMsg(SystemMsgs.createNewRecord());
      // setOpenSnackBar(true);
      history.goBack();
    },
  });

  const [
    updateSubcontractTender,
    { loading: mutationTenderLoading, error: mutationTenderError },
  ] = useUpdateSubcontractTenderMutation({
    onError: error => {
      console.log('ERROR', error);
      // FOR DUPLICATE DIALOG AFTER MUTATION
      setErrMsg(error.message.substr(15, error.message.length - 1));
      setErrorDia(true);
    },
    onCompleted: data => {
      // setSnackBarMsg(SystemMsgs.updateRecord());
      // setOpenSnackBar();
      history.goBack();
    },
  });

  const {
    loading: costCategoryLoading,
    error,
    data: { getCostCategory } = { getCostCategory: [] },
  } = useGetCostCategoryQuery({
    fetchPolicy: 'network-only',
    variables: {
      contractID: contractID,
      costClass: CostClass.Subcontract,
    },
  });

  const { data: { getSite } = { getSite: [] } } = useGetSiteQuery({
    fetchPolicy: 'network-only',
    variables: {
      contractID: contractID,
    },
  });

  // Query for contracts
  const { data: { getContract } = { getContract: [] } } = useGetContractQuery({
    fetchPolicy: 'network-only',
    variables: { ID: contractID },
  });

  const [
    selectedStartDate,
    setSelectedStartDate,
  ] = React.useState<Date | null>();
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>();

  //validation
  const validationSchema = yup.object().shape({
    refNo: CommonYupValidation.requireField(SystemMsgs.docRef()),
    subcontractPackage: CommonYupValidation.requireField(
      SystemMsgs.costCategory(),
    ),
    subConTitle: CommonYupValidation.requireField(SystemMsgs.title()),
    description: CommonYupValidation.requireField(SystemMsgs.description()),
    // Tender_date: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    // Close_date: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    // Start_Time: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    // End_Time: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    tenderDate: CommonYupValidation.requireField(SystemMsgs.dateSelection()),

    startDate: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    startTime: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    closeDate: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    endTime: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    budgetedAmount: yup
      .number()
      .required('Require a proper amount')
      .max(
        getContract[0]?.contractSum,
        'Subcontract sum must be less than contract sum',
      )
      .min(1, 'The amount should not be zero'),
  });

  // //Handle Start Date
  // const handleStartDateChange = date => {
  //   setSelectedStartDate(date);
  // };
  // //Handle End Date
  // const handleEndDateChange = (date: Date | null) => {
  //   setSelectedEndDate(date);
  // };

  //handle change for all
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setSubConTender({ ...subConTender, [name]: value });
  };
  var loading = false;
  //When the save button is pressed
  const onSubmit = (data: any) => {
    console.log(data);

    // // concatenate the date and time together
    // var startTimeString = data.Start_Time.toString().substring(
    //   16,
    //   data.Start_Time.toString().length,
    // );
    // var endTimeString = data.End_Time.toString().substring(
    //   16,
    //   data.End_Time.toString().length,
    // );
    // var startDateTime =
    //   data.Tender_date.toString().substr(0, 16) + startTimeString;
    // var endDateTime = data.Close_date.toString().substr(0, 16) + endTimeString;

    // // add 2 new properties to the data object
    // data['startDateTime'] = startDateTime;
    // data['endDateTime'] = endDateTime;

    // // Delete unnecessary data object properties
    // delete data.Start_Time;
    // delete data.End_Time;
    // delete data.Tender_date;
    // delete data.Close_date;

    if (mode == 'add') {
      console.log('the data', data);

      //add the data with GraphQL mutation
      createSubcontractTender({
        variables: {
          input: {
            createdBy: user?.name,
            contractID: contractID,
            siteID: data.site,
            costCategoryID: data.subcontractPackage,
            refNo: data.refNo,
            title: data.subConTitle,
            description: data.description,
            budgetAmt: parseFloat(amtNumStr(data.budgetedAmount)),
            tenderDate: data.tenderDate,
            startDate: data.startDate,
            startTime: data.startTime,
            closeDate: data.closeDate,
            endTime: data.endTime,

            file: files,
          },
        },
      });
    } else {
      //update the data

      updateSubcontractTender({
        variables: {
          input: {
            ID: subconTenderID,
            contractID: contractID,
            siteID: data.site,
            costCategoryID: data.subcontractPackage,
            refNo: data.refNo,
            title: data.subConTitle,
            description: data.description,
            budgetAmt: parseFloat(amtNumStr(data.budgetedAmount)),
            tenderDate: data.tenderDate,
            startDate: data.startDate,
            startTime: data.startTime,
            closeDate: data.closeDate,
            endTime: data.endTime,
            file: files,
          },
        },
      });
    }
  };

  // Use for attachment
  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment();

  const [
    loadDoc,
    { loading: DocLoad, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    variables: { refID: editData?.ID },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing);
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL));
    },
  });

  useEffect(() => {
    if (mode === 'edit') {
      loadDoc();
    }
  }, [mode]);

  const { handleSubmit, register, setValue, errors, control } = useForm<
    subConTender
  >({
    mode: 'all',
    defaultValues: {
      site: editData ? editData?.siteID : '',
      refNo: editData ? editData?.refNo : '',
      subcontractPackage: editData ? editData?.costCategoryID : '',
      subConTitle: editData ? editData?.title : '',
      description: editData ? editData?.description : '',
      // Tender_date: editData ? editData?.tenderDate : new Date(),
      // Close_date: editData ? editData?.closeDate : new Date(),
      // Start_Time: editData ? editData?.tenderDate : new Date(),
      // End_Time: editData ? editData?.closeDate : new Date(),
      tenderDate: editData ? editData?.tenderDate : '',
      startDate: editData ? editData?.startDate : '',
      startTime: editData ? editData?.startTime : '',
      closeDate: editData ? editData?.closeDate : '',
      endTime: editData ? editData?.endTime : '',
      // Tender_date: mode === 'add' ? '' : editData?.tenderDate,
      // Close_date: mode === 'add' ? '' : editData?.closeDate,
      // Start_Time: mode === 'add' ? '' : editData?.tenderDate,
      // End_Time: mode === 'add' ? '' : editData?.closeDate,
      budgetedAmount: editData ? editData?.budgetAmt : null,
      Attachment: null,
    },
    resolver: yupResolver(validationSchema),
  });

  console.log(updateSubcontractTender, 'yeeeeeet');

  //-----------------------close date calculation----------------------
  var currentDate = moment(new Date());
  var futureMonth = moment(currentDate).add(1, 'M');
  var futureMonthEnd = moment(futureMonth).endOf('month');

  if (
    currentDate.date() != futureMonth.date() &&
    futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))
  ) {
    futureMonth = futureMonth.add(1, 'd');
  }

  console.log(futureMonth, 'plus 1 month');

  //----------------------FOR DUPLICATE DIALOG---------------------------
  const [errorDia, setErrorDia] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');

  return (
    <>
      {loading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => history.goBack()}
        // smTitle={user?.name}
        smTitle={'Subcon Tender'}
        title={user?.companyName}
        // currency={`Myr`}
        routeSegments={[
          { name: 'Subcon Tender' },
          { name: 'Subcon Tender', current: true },
        ]}
        // routeSegments={[{ name: 'Subcontracts ', current: true }]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />
      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <ContentWrapper footer>
          <CardContents>
            <Controller
              as={TextField}
              name="refNo"
              label="Reference No."
              required
              autoComplete="off"
              multiline={true}
              margin="normal"
              value={subConTender.refNo}
              onChange={handleChange}
              control={control}
              ref={register}
              helperText={errors?.refNo?.message}
              error={!!errors?.refNo ? true : false}
              className="left"
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="tenderDate"
                margin="normal"
                // id="Tender_date"
                label="Tender Date"
                format="dd MMM yyyy"
                // value={null}
                value={mode === 'add' ? new Date() : editData?.tenderDate}
                defaultValue={editData ? editData?.tenderDate : new Date()}
                onChange={(date: Date | null) => {}}
                control={control}
                // ref={register}

                allowKeyboardControl
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className="right"
                // ref={register}
                inputRef={register()}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.tenderDate ? true : false}
                helperText={errors?.tenderDate?.message}
              />
            </MuiPickersUtilsProvider>

            <Controller
              as={
                <TextField select label="Site Address">
                  {getSite?.map((el, index) => (
                    <MenuItem value={el.ID} key={index}>
                      {el.name}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="site"
              required
              autoComplete="off"
              control={control}
              select
              ref={register}
              defaultValue={subConTender.Subcon_package}
              helperText={errors?.subcontractPackage?.message}
              error={!!errors?.subcontractPackage ? true : false}
            />

            <Controller
              as={
                <TextField select label="Subcontract Trade">
                  {getCostCategory?.map((el, index) => (
                    <MenuItem value={el.ID} key={index}>
                      {el.name}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="subcontractPackage"
              required
              autoComplete="off"
              control={control}
              select
              ref={register}
              defaultValue={subConTender.Subcon_package}
              helperText={errors?.subcontractPackage?.message}
              error={!!errors?.subcontractPackage ? true : false}
            />

            <Controller
              as={TextField}
              name="subConTitle"
              label="Subcontract Title"
              required
              defaultValue={subConTender.subConTitle}
              value={subConTender.subConTitle}
              onChange={handleChange}
              control={control}
              ref={register}
              // onChange={e => {
              //   let selectedCust = subcontractorList?.filter(
              //     x => x?.ID === e?.target?.value,
              //   )[0];
              //   setSelectedCustomer(selectedCust);
              // }}
              helperText={errors?.subConTitle?.message}
              error={errors?.subConTitle ? true : false}
            />
            {/* {subcontractorList?.map((el, index) => (
                  <MenuItem key={index} value={el?.ID}>
                  {el?.name}
                  </MenuItem>
              ))} */}

            <Controller
              as={TextField}
              name="description"
              label="Description"
              required
              value={subConTender.description}
              onChange={handleChange}
              control={control}
              ref={register}
              helperText={errors?.description?.message}
              error={errors?.description ? true : false}
              // onChange={e => {
              //   let selectedCust = subcontractorList?.filter(
              //     x => x?.ID === e?.target?.value,
              //   )[0];
              //   setSelectedCustomer(selectedCust);
              // }}
            />
            {/* {subcontractorList?.map((el, index) => (
                  <MenuItem key={index} value={el?.ID}>
                  {el?.name}
                  </MenuItem>
              ))} */}

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="startDate"
                margin="normal"
                // id="Tender_date"
                label="Start Date"
                format="dd MMM yyyy"
                value={mode === 'add' ? new Date() : editData?.startDate}
                defaultValue={editData ? editData?.startDate : new Date()}
                // value={new Date()}
                // defaultValue={new Date()}
                onChange={(date: Date | null) => {}}
                control={control}
                // ref={register}

                //allowKeyboardControl
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className="left"
                inputRef={register()}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.startDate ? true : false}
                helperText={errors?.startDate?.message}
              />
              <Controller
                // as={KeyboardTimePicker}
                as={TextField}
                name="startTime"
                margin="normal"
                // id="time-picker"
                type="time"
                label="Start Time"
                value={mode === 'add' ? '09:00' : editData?.startTime}
                defaultValue={editData ? editData?.startTime : '09:00'}
                // defaultValue="09:00"
                // value={new Date()}
                // onChange={(date: Date | null) => {}}
                control={control}
                // ref={register}
                //allowKeyboardControl
                // KeyboardButtonProps={{
                //   'aria-label': 'change time',
                // }}
                className="right"
                inputRef={register()}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.startTime ? true : false}
                helperText={errors?.startTime?.message}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="closeDate"
                margin="normal"
                // id="Tender_close_date"
                label="Close Date"
                format="dd MMM yyyy"
                value={mode === 'add' ? futureMonth : editData?.closeDate}
                defaultValue={editData ? editData?.closeDate : futureMonth}
                // defaultValue={futureMonth}
                // value={new Date()}
                // ref={register}
                onChange={(date: Date | null) => {}}
                control={control}
                //allowKeyboardControl
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className="left"
                inputRef={register()}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.closeDate ? true : false}
                helperText={errors?.closeDate?.message}
              />
              <Controller
                // as={KeyboardTimePicker}
                as={TextField}
                name="endTime"
                margin="normal"
                type="time"
                id="time-picker"
                label="End Time"
                // defaultValue="18:00"
                // value={new Date()}
                value={mode === 'add' ? '18:00' : editData?.endTime}
                defaultValue={editData ? editData?.endTime : '18:00'}
                // ref={register}
                //allowKeyboardControl
                // onChange={(date: Date | null) => {}}
                control={control}
                // KeyboardButtonProps={{
                //   'aria-label': 'change time',
                // }}
                className="right"
                inputRef={register()}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.endTime ? true : false}
                helperText={errors?.endTime?.message}
              />
            </MuiPickersUtilsProvider>

            <Controller
              name="budgetedAmount"
              control={control}
              ref={register}
              defaultValue={editData?.budgetAmt}
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  label="Budgeted Amount"
                  thousandSeparator
                  allowNegative={false}
                  customInput={TextField}
                  multiline={true}
                  isNumericString
                  decimalScale={2}
                  fixedDecimalScale
                  defaultValue={editData?.budgetAmt}
                  onValueChange={values => {
                    let { floatValue } = values;
                    onChange(
                      (value = values.floatValue),
                      register(`budgetedAmount`),
                      setValue(`budgetedAmount`, floatValue),
                    );
                  }}
                  helperText={
                    errors.budgetedAmount && errors?.budgetedAmount?.message
                  }
                  error={errors.budgetedAmount && errors.budgetedAmount}
                />
                // label="Budgeted Amount"
                //value={subConTender.budgetedAmount}
                //onChange={handleChange}
                // ref={register}
                // control={control}
              )}
            />

            <FileUploadInput
              label="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </CardContents>
          <Footer
            options={[
              {
                name: 'Save',
                color: 'primary',
                onClick: () => {
                  handleSubmit(onSubmit);
                  // history.goBack()
                },
                props: {
                  type: 'submit',
                },
              },
            ]}
          />
        </ContentWrapper>
      </form>

      {/* <DuplicateDialog
          errorDia={errorDia}
          setErrorDia={setErrorDia}
          errorMsg={errMsg}
          uniqueMsg={`Unique Reference No.`}
        /> */}
    </>
  );
};
